const initialState = {
  name: "",
  point: {
    admins: []
  },
  admins: [],
  billingaddress: {
    company: "",
    street: "",
    number: "",
    zipcode: "",
    city: "",
    email: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_PARTNER_INFO_FULFILLED": {
      return action.payload.data;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
