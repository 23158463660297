import {
  toastr
} from 'react-redux-toastr'
import {
  server
} from '../app/serverapi';
import * as R from 'ramda';

import {
  ActionCreators
} from 'redux-undo';

// import printJS from '../printjs/index.js';


function asyncLoadReservations(dispatch) {
  server.get('reservations').then((response) => {
    dispatch({
      type: 'LOAD_RESERVATIONS_FULFILLED',
      payload: response
    });
  });
}

// Liefert ein Objekt zurück mit Type und Payload.
// Alle Reducer werden nacheinander mit diesem Objekt
// aufgerufen und können, wenn wie wollen anhand des Typs entscheiden
// ob sie eine Änderung am State vornehmen wollen. Die payload
// liefert Daten mit, die vermutlich für die Änderung des States
// nötig sind.
export function changeFormFieldAmount(id, value) {
  return {
    type: 'CHANGE_FORM_FIELD_AMOUNT',
    payload: {
      id,
      value,
    },
  };
}

export function increaseFormFieldAmount(id) {
  return {
    type: 'INCREASE_FORM_FIELD_AMOUNT',
    payload: {
      id,
    },
  };
}

export function decreaseFormFieldAmount(id) {
  return {
    type: 'DECREASE_FORM_FIELD_AMOUNT',
    payload: {
      id,
    },
  };
}

export function resetTicket() {
  return {
    type: 'RESET_TICKET',
  };
}

export function changeFormFieldValue(id, value) {
  return {
    type: 'CHANGE_FORM_FIELD_VALUE',
    payload: {
      id,
      value,
    },
  };
}

export function switchFormFieldValue(id, value) {
  return {
    type: 'SWITCH_FORM_FIELD_VALUE',
    payload: {
      id,
      value,
    },
  };
}

export function changeFormFieldSelected(id) {
  return {
    type: 'CHANGE_FORM_FIELD_SELECTED',
    payload: {
      id,
    }
  };
}

// Liefert ein Objekt zurück, genau wie oben, nur dass die payload
// kein Objekt ist, sondern ein Promise. Die React-Promise-Middleware
// fängt diesen Fall ab und führt das Promise aus. Je nachdem, ob das
// Promise fullfilled oder rejected, wird eine neue Action generiert,
// in diesem Fall UPDATE_TICKET_FULLFILLED oder UPDATE_TICKET_REJECTED
export function commitConstraint(ticket) {
  return {
    type: 'UPDATE_TICKET',
    payload: server.post('ticket', ticket),
  };
}

export function reserveTicket(currentevent) {
  return (dispatch) => {
    server.post('reserve', currentevent).then((response) => {
      toastr.success('Erfolg', 'Das Ticket wurde in den Warenkorb gelegt.');
      window.scrollTo(0, 0);
      dispatch({
        type: 'RESERVE_TICKET_FULFILLED',
        payload: response
      });
    }).then(() => {
      asyncLoadReservations(dispatch);
    });
  };
}

// export function buyTicket(reservationid) {
//   return (dispatch) => {
//     server.post('buy', [{
//       reservationid,
//     }]).then((response) => {
//       if (response.data.success === true) {
//         // window.location = response.data.url;
//         printJS(response.data.url)
//       }
//             // console.log(response.data);
//       dispatch({ type: 'BUY_TICKET_FULFILLED', payload: response });
//     }).then(() => {
//       asyncLoadReservations(dispatch);
//     });
//   };
// }

export function buyAllTickets() {
  return (dispatch, getState) => {
    const {
      reservations,
      reservationsettings,
      eventlist,
    } = getState();
    const reservationids = reservations.items.map(item => ({
      reservationid: item.reservationid,
    }));

    const reqdata = {
      items: reservationids,
      shipping: reservationsettings.shipping,
      email: reservationsettings.email,
      hidePriceOnTicket: reservationsettings.hidePriceOnTicket,
      shippingaddress: reservationsettings.shippingaddress,
    };
    server.post('buy', reqdata).then((response) => {
      if (response.data.success === true) {
        const type = eventlist.preselect === null ? 'BUY_TICKET_FULFILLED' : 'BUY_PRESELECTED_TICKET_FULFILLED';
        const payload = {
          url: response.data.url,
        };
        dispatch({
          type,
          payload
        });

        if (eventlist.preselect !== null) {
          openTicket(dispatch, eventlist.preselect);
        }

        // Historie des Ticket-Konfigurators löschen
        dispatch(ActionCreators.clearHistory());

      } else {
        // console.log(response);
        toastr.error('Fehler', response.data.message);
        dispatch({
          type: 'BUY_TICKET_REJECTED',
          payload: response
        });
      }

    }).then(() => {
      asyncLoadReservations(dispatch);
    });
  };
}


export function unreserve(reservationid) {
  return (dispatch) => {
    server.post('unreserve', [{
      reservationid,
    }]).then((response) => {
      toastr.info('Information', 'Das Ticket wurde aus dem Warenkorb entfernt.');
      dispatch({
        type: 'UNRESERVE_TICKET_FULFILLED',
        payload: response
      });
    }).then(() => {
      asyncLoadReservations(dispatch);
    });
  };
}

export function unreserveAll() {
  return (dispatch) => {
    server.post('unreserveall').then((response) => {
      toastr.info('Information', 'Alle Tickets wurde aus dem Warenkorb entfernt.');
      dispatch({
        type: 'UNRESERVE_ALL_FULFILLED',
        payload: response
      });
    }).then(() => {
      asyncLoadReservations(dispatch);
    });
  };
}


export function loadReservations() {
  return {
    type: 'LOAD_RESERVATIONS',
    payload: server.get('reservations').then(),
  };
  // return (dispatch) => {
  //   server.get('reservations').then((response) => {
  //     dispatch({ type: 'LOAD_RESERVATIONS_FULFILLED', payload: response.data });
  //   });
  // };
}

export function loadTicket(eventid) {
  return {
    type: 'LOAD_TICKET',
    payload: server.post('/ticket', {
      globalid: eventid
    }),
  };
}

export function updateEventList(query, page) {
  return (dispatch) => {
    server.get('events?search=' + query + "&page=" + page + "&windowHeight=" + getWindowHeight()).then((response) => {
      dispatch({
        type: 'EVENT_LIST_FULFILLED',
        payload: response
      });
      if (response.data.preselect) {
        openTicket(dispatch, response.data.preselect)
      }
    });
  };
}

export function setCurrentEvent(globalid) {
  return {
    type: 'SET_CURRENT_EVENT',
    payload: globalid,
  };
}

function openTicket(dispatch, eventid) {
  dispatch(setCurrentEvent(eventid));
  dispatch(loadTicket(eventid));
}

function getWindowHeight() {
  var myHeight = 0;
  if (typeof(window.innerHeight) === 'number') {
    //Non-IE
    myHeight = window.innerHeight;
  } else if (document.documentElement && (document.documentElement.clientHeight)) {
    //IE 6+ in 'standards compliant mode'
    myHeight = document.documentElement.clientHeight;
  } else if (document.body && (document.body.clientHeight)) {
    //IE 4 compatible
    myHeight = document.body.clientHeight;
  }
  return myHeight;
}
