const initialState = {
    orderlist: [],
    showdetails: false,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'HISTORY_LOAD_FULFILLED':
        {
          return { ...state,
            orderlist: action.payload.data
          };
        }
  
      case 'HISTORY_LOAD_REJECTED':
        {
          return state;
        }
      case 'HISTORY_CANCEL_ORDER_FULFILLED':
      case 'HISTORY_CANCEL_TICKET_FULFILLED':
        {
          if (action.payload.success === true) {
            return { ...state,
              orderlist: action.payload.orders
            }
          }
          return state;
        }
      case 'HISTORY_CANCEL_ORDER_REJECTED':
      case 'HISTORY_CANCEL_TICKET_REJECTED':
        {
          return state;
        }
      case 'HISTORY_SWITCH_DETAILS':
        {
          const newstate = {...state};
          newstate.showdetails = newstate.showdetails === action.payload ? false : action.payload;
          return newstate;
        }
  
      default:
        {
          return state;
        }
    }
  };
  
  export default reducer;
  