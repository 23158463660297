/* eslint react/no-danger: 0 */

import React from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import {Alert} from "react-bootstrap";
import SelectQuantity from "./QuestionSelectQuantity";
import SelectSingle from "./QuestionSelectSingle";
import SelectForm from "./QuestionSelectForm";
import TicketSummary from "./TicketSummary/";

class Questions extends React.Component {
  render() {
    const undoAvailable =
      (this.props.tickethistory.past.length === 1 &&
        this.props.tickethistory.past[0].globalid !== false) ||
      this.props.tickethistory.past.length > 1;

    let view = null;
    if (this.props.question && this.props.question.viewtype) {
      switch (this.props.question.viewtype) {
        case "select-quantity": {
          view = (
            <SelectQuantity
              price={this.props.price}
              changeAmount={this.props.changeAmount}
              increaseAmount={this.props.increaseAmount}
              decreaseAmount={this.props.decreaseAmount}
              commitConstraint={this.props.commitConstraint}
              formFields={this.props.question.formfields}
              description={this.props.question.description}
              error={this.props.question.error}
              undoAvailable={undoAvailable}
            />
          );
          break;
        }
        case "select-single": {
          view = (
            <SelectSingle
              price={this.props.price}
              changeSelected={this.props.changeSelected}
              commitConstraint={this.props.commitConstraint}
              formFields={this.props.question.formfields}
              description={this.props.question.description}
              error={this.props.question.error}
              value={this.props.question.value}
              undoAvailable={undoAvailable}
            />
          );
          break;
        }
        case "form": {
          view = (
            <SelectForm
              price={this.props.price}
              changeValue={this.props.changeValue}
              switchValue={this.props.switchValue}
              commitConstraint={this.props.commitConstraint}
              formFields={this.props.question.formfields}
              description={this.props.question.description}
              error={this.props.question.error}
              undoAvailable={undoAvailable}
            />
          );
          break;
        }
        default: {
          view = <div />;
        }
      }
    }

    const ticketinfo = {
      __html: this.props.ticket.event.info
    };

    const message = this.props.ticket.message ? <Alert bsStyle="warning">{this.props.ticket.message}</Alert> : null;

    return (
      <div id="Questions">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              {this.props.ticket.event.name}
            </h3>
          </div>
          <div className="panel-body">
            <TicketSummary ticket={this.props.ticket} />
            {message}
            {view}
          </div>
          <div className="panel-footer">
            {this.props.ticket.event.place && <div><FontAwesome fixedWidth name="clock-o" /> <strong>Ort:</strong> {this.props.ticket.event.place}</div>}
            {this.props.ticket.event.date && <div><FontAwesome fixedWidth name="map-marker" /> <strong>Datum:</strong>  {this.props.ticket.event.date}</div>}
            {this.props.ticket.event.host && <div><FontAwesome fixedWidth name="star" /> <strong>Veranstalter:</strong> {this.props.ticket.event.host}</div>}

          </div>
        </div>
        <div dangerouslySetInnerHTML={ticketinfo} />
      </div>
    );
  }
}

export default connect(state => ({
  tickethistory: state.ticket
}))(Questions);
