import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';
import {reducer as toastrReducer} from 'react-redux-toastr'
import eventlistReducer from './eventlist';
import authReducer from './auth';
// import currenteventReducer from './currentEvent';
import ticketReducer from './ticket';
import loaderReducer from './loader';
import reservationsReducer from './reservations';
import reservationsettingsReducer from './reservationssettings';
// import eventCacheReducer from './eventcache';
import checkoutReducer from './checkout';
import cancellationsReducer from './cancellations';
import historyReducer from './history';
import shopReducer from './shop';
import partnerSettingsReducer from './partnerSettings';
import partnerInfoReducer from './partnerInfo';
// import usersReducer from './users';
import pointsReducer from './points';
import statsReducer from './stats';
import passwordReducer from './password';

export default combineReducers({
  loader: loaderReducer,
  auth: authReducer,
  reservations: reservationsReducer,
  eventlist: eventlistReducer,
  // currentevent: currenteventReducer,
  // eventcache: eventCacheReducer,
  checkout: checkoutReducer,
  shop: shopReducer,
  reservationsettings: reservationsettingsReducer,
  cancellations: cancellationsReducer,
  history: historyReducer,
  toastr: toastrReducer,
  partnerSettings: partnerSettingsReducer,
  partnerInfo: partnerInfoReducer,
  // users: usersReducer,
  points: pointsReducer,
  password: passwordReducer,
  stats: statsReducer,
  ticket: undoable(ticketReducer, { filter: includeAction(['UPDATE_TICKET_FULFILLED', 'LOAD_TICKET_FULFILLED']) }) });
