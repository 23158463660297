import React from 'react';
import { connect } from 'react-redux';
import { editUser, saveEditUser } from '../../actions/pointsActions';

class ListRow extends React.Component {

  startEditing() {
    this.props.dispatch(editUser(this.props.user));
  }

  toggleStatus() {
    const { user } = this.props;
    user.status = user.status === 1 ? 0 : 1;
    this.props.dispatch(saveEditUser({ user, pointid: this.props.pointid }));
  }

  render() {
    const user = this.props.user;
    const linkName = user.status === 1 ? "deaktivieren" : "aktivieren";

    const editdisabled = this.props.currentrole === "pointadmin" && user.role !== "seller";

    var userrole;
    switch (user.role) {
      case "pointadmin":
        userrole = "VVK-Stellen-Admin";
        break;
      case "seller":
        userrole = "Verkäufer";
        break;
    }

    return (
      <tr>
        <td>{user.lastname}</td>
        <td>{user.firstname}</td>
        <td>{user.username}</td>
        <td>{userrole}</td>
        <td style={{ width: '190px' }}>
          <button disabled={editdisabled} onClick={this.startEditing.bind(this)} className="btn btn-xs btn-info">bearbeiten</button>&nbsp;
          <button disabled={editdisabled} onClick={this.toggleStatus.bind(this)} className="btn btn-xs btn-warning">{linkName}</button>
        </td>
      </tr>
    );
  }
}

export default connect()(ListRow);
