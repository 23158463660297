import * as R from 'ramda';
import {
  server
} from '../app/serverapi';



// WORKING::

// Liste aller Points laden (Partner-Admin)
export function loadPoints() {
  return {
    type: 'LOAD_POINTS',
    payload: server.get('points'),
  };
}

export function addPoint() {
  return {
    type: 'START_ADDING_POINT',
  };
}


// einen Point bearbeiten (öffnet das Modal)
export function editPoint(pointid) {
  return {
    type: 'START_EDITING_POINT',
    payload: pointid,
  };
}

// Point bearbeiten abbrechen (schließt das Modal)
export function cancelEditPoint() {
  return {
    type: 'CANCEL_EDITING_POINT',
  };
}

// bearbeiteten Point speichern (schließt das Modal)
export function saveEditPoint(point) {
  return (dispatch) => {
    const pointid = point.pointid;
    const sendpoint = R.pick(['city', 'name', 'number', 'status', 'status', 'street', 'zipcode'], point);
    const posturl = pointid ? 'points/' + pointid : 'points';

    server.post(posturl, sendpoint).then((response) => {
      dispatch(loadPoints());
      dispatch({
        type: 'SAVE_EDITING_POINT_FULFILLED',
        payload: response
      });
    });
  }
}

// Point bearbeiten: Daten updaten (onChange in input-Feldern)
export function updatePoint(name, value) {
  return {
    type: 'UPDATE_EDITING_POINT',
    payload: {
      name,
      value
    }
  };
}

export function loadPointDetails(pointid) {
  return {
    type: 'LOAD_POINT_DETAILS',
    payload: server.get('points/' + pointid),
  };
}

export function editUser(user) {
  return {
    type: 'START_EDITING_USER',
    payload: { ...user,
      password: '',
      password2: '',
      passwordstate: '',
      pwsetactive: false
    },
  };
}

export function addUser(pointid) {
  // console.log("adduser", pointid)
  return {
    type: 'START_ADDING_USER',
    payload: pointid
  };
}


export function cancelEditUser() {
  return {
    type: 'CANCEL_EDITING_USER',
  };
}

export function saveEditUser({user, pointid}) {
  if (user.password !== user.password2) {
    return {
      type: 'SAVE_EDITING_USER_PASSWORDS_NOT_MATCHING',
    }
  }

  if (user.userid === false && user.password === "") {
    return {
      type: 'SAVE_EDITING_USER_PASSWORDS_EMPTY',
    }
  }

  return (dispatch) => {
    const userid = user.userid;
    const senduser = R.pick(['email', 'firstname', 'lastname', 'role', 'salutation', 'status', 'username', 'password'], user);

    let posturl ="points/" + pointid;
    posturl += userid > 0 ? "/users/" + userid : "/users";

    server.post(posturl, senduser).then((response) => {
      dispatch(loadPointDetails(pointid));
      dispatch({
        type: 'SAVE_EDITING_USER_FULFILLED',
        payload: response
      });
    });
  }
}


export function updateUser({
  name,
  value
}) {
  return {
    type: 'UPDATE_EDITING_USER',
    payload: {
      name,
      value
    }
  };
}




// UNGETESTET:


//
//
// ///////////////////////////////////////////////////////////////////
//
//
//
//



// export function deactivateUser(user) {
//   const {
//     userid
//   } = user;
//   return (dispatch) => {
//     server.post('users', {
//       status: 0,
//       userid
//     }).then((response) => {
//       dispatch(loadUsers());
//       dispatch({
//         type: 'DEACTIVATE_USER_FULFILLED',
//         payload: response
//       });
//     });
//   }
// }
//
// // wirc noch nicht benutzt
// export function activateUser(user) {
//   const {
//     userid
//   } = user;
//   return (dispatch) => {
//     server.post('users', {
//       status: 1,
//       userid
//     }).then((response) => {
//       dispatch(loadUsers());
//       dispatch({
//         type: 'ACTIVATE_USER_FULFILLED',
//         payload: response
//       });
//     });
//   }
// }
