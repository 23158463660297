import React from 'react';

class EinstellungenPartnerInfos extends React.Component {

  render() {
    const {company, street, number, zipcode, city, billingemail} = this.props.billingaddress;
    return (
        <div className="panel-body">
                <h3>Rechnungsadresse</h3>
                <p>
                  <b>Firma</b> {company}<br />
                  <b>Straße</b> {street} <b>Hausnummer</b> {number}<br />
                  <b>PLZ</b> {zipcode} <b>Stadt</b> {city}<br />
                </p>

                <p>Änderungen der angezeigten Daten nur über die tivents UG möglich.</p>

                <p>
              tivents UG (haftungsbeschränkt)<br />
              Mansfelder Str. 56<br />
              06108 Halle (Saale)<br />
                </p>
                <p>E-Mail: <a href="mailto:vorverkauf@tivents.de">vorverkauf@tivents.de</a></p>

                <p>Telefon: 0345 9639 4097</p>
                <p>
              Bitte haben Sie Verständnis, dass wir aus Sicherheitsgründen am Telefon und bei
              E-Mails, die nicht von Ihrer oben genannten E-Mail-Adresse versandt wurden, keine
              Änderungen vornehmen und nur allgemeine Informationen herausgeben können.
            </p>

              </div>

    );
  }
}

export default EinstellungenPartnerInfos;
