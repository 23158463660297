const initialState = {
  items: [],
  stats: {
    totalprice: 0,
    fees: [],
    quantity: 0,
    numberofitems: 0,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_RESERVATIONS_FULFILLED':
      {
        return { items: action.payload.data.items, stats: action.payload.data.stats };
      }
    case 'RESERVE_TICKET_FULFILLED':
      {
        return state;
      }
    // case 'RESERVATION_TOGGLE_SHOW_DETAILS_STATE':
    //   {
    //     console.log(state);
    //     const items = state.items.map(item =>
    //       ({ ...item, open: (item.reservationid === action.payload)
    // ? !item.open : !!item.open }));
    //
    //     // const newstate = { ...state };
    //     // const index = newstate.items.findIndex((obj => obj.reservationid === action.payload));
    //     // newstate.items[index].open =
    //     //   (typeof newstate.items[index].open !== 'undefined')
    //     //     ? !newstate.items[index].open
    //     //     : true;
    //     return { ...state, items };
    //   }
    default: {
      return state;
    }
  }
}
