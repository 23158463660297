import React from 'react';

class FormFieldMultiselect extends React.Component {

  onSwitchValue(event) {
    this.props.switchValue(this.props.data.id, event.target.value);
  }

  render() {
    const { name, value, options } = this.props.data;
    const htmloptions = options.map(option => <div className="checkbox" key={option.id}>
      <label>
        <input type="checkbox" value={option.id} checked={value.indexOf(option.id) > -1 ? 'checked' : ''} onChange={this.onSwitchValue.bind(this)} />
        { option.value }
      </label>
    </div>);
    return (
      <div className="form-group valueselect">
        <label className="control-label">{name}</label>
        { htmloptions }
      </div>
    );
  }
}

export default FormFieldMultiselect;
