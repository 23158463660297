import {
    server,
} from '../app/serverapi';

export function logout() {
  return {
    type: 'UNSET_CURRENT_USER',
    payload: server.get('/logout'),
  };
}

export function handleInvalidedSession() {
  return {
    type: 'HANDLE_INVALIDATED_SESSION',
  };
}

export function checkIfLoggedIn() {
  return {
    type: 'GET_CURRENT_USER',
    payload: server.get('/userdata'),
  };
}

export function tryLogin(username, password) {
  return {
    type: 'GET_CURRENT_USER',
    payload: server.post('/userdata', {
      username,
      password,
    }),
  };
}

// export function setAuthKey(authkey) {
//   return {
//     type: 'SET_AUTH_KEY',
//     payload: authkey,
//   };
// }
