import React from 'react';
import { Link } from 'react-router-dom';
import Settings from './Admin-Settings';
import PointDetails from './Admin-PointDetails';
import PointsList from './Admin-PointList';
import PartnerSettings from './Admin-PartnerSettings';
import { CSSTransitionGroup } from 'react-transition-group'

function Admin({ match }) {
  // console.log(match);
  // <ul className="nav nav-tabs">
  //   <li role="presentation" className={match.params.tab === 'einstellungen' ? 'active' : ''} ><Link to="/verwaltung/einstellungen">persönliche Einstellungen</Link></li>
  //   <li role="presentation" className={match.params.tab === 'einstellungenpartner' ? 'active' : ''} ><Link to="/verwaltung/einstellungenpartner">persönliche Einstellungen (Partner)</Link></li>
  //   <li role="presentation" className={match.params.tab === 'vvk' ? 'active' : ''} ><Link to="/verwaltung/vvk">Meine VVK-Stelle</Link></li>
  //   <li role="presentation" className={match.params.tab === 'vvklist' ? 'active' : ''} ><Link to="/verwaltung/vvklist">Meine VVK-Stellen</Link></li>
  //   <li role="presentation" className={match.params.tab === 'partnerSettings' ? 'active' : ''} ><Link to="/verwaltung/partnerSettings">Verkaufseinstellungen</Link></li>
  // </ul>

  const pointid = match.params.pointid ? match.params.pointid : false;
  return (
    <CSSTransitionGroup
      transitionName="example"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={true}
      transitionEnterTimeout={500}
      transitionLeave={true}
      transitionLeaveTimeout={500}
      >
    <div className="container">
      { (match.params.tab === 'einstellungen') && <Settings /> }
      { (match.params.tab === 'vvk') && <PointDetails pointid={pointid} /> }
      { (match.params.tab === 'vvklist') && <PointsList /> }
      { (match.params.tab === 'partnerSettings') && <PartnerSettings /> }
    </div>
    </CSSTransitionGroup>
  );
}

export default Admin;
