import * as React from "react";
import BtnGroupButton from "./BtnGroupButton";

class TypeSelector extends React.Component {

	render() {
		const typeButtons = [
			{ name: "orders", title: "Bestellungen" },
			{ name: "totals", title: "Gesamtverkäufe" }
		].map(type =>
			<BtnGroupButton
				key={"type" + type.name}
				onChange={this.props.onChange}
				active={this.props.type == type.name}
				data={type}
			/>
		);

		return (
			<div
				className="btn-group"
				role="group"
				aria-label="Typ auswählen"
			>
				{typeButtons}
			</div>
		);
	}
}

export default TypeSelector;
