/* eslint no-unused-vars: 0 */

// URL-Token auslesen und global setzen
// import "babel-polyfill";
import "./app/shim";
import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap.native";
import "./themes/bootstrap-default.scss";
import "react-redux-toastr/src/styles/index.scss";
import moment from 'moment';
import posstore from "./app/store";

import Layout from "./pages/Layout";

moment.locale("de");

function inititialize() {
  ReactDOM.render(
    <Provider store={posstore}>
      <Layout />
    </Provider>,
    document.getElementById("root")
  );
}

require("es6-promise/auto");

// if (!window.Intl || !window.Promise) {
// // console.log("lade app", window.Promise, window.Intl);
//   // Webpack parses the inside of require.ensure at build time to know that intl
//   // should be bundled separately. You could get the same effect by passing
//   // ['intl'] as the first argument.
//   // console.log("intl oder promise fehlt");
//   require.ensure([], () => {
//     // Ensure only makes sure the module has been downloaded and parsed.
//     // Now we actually need to run it to install the polyfill.
//     // console.log("ensure");
//     if (!window.Intl) {
//       // console.log("lade intl polyfill");
//       require("intl");
//       require("intl/locale-data/jsonp/de-DE.js");
//     }

//     if (!window.Promise) {
//       // console.log("lade promise polyfill");
//     }
//     // Carry on
//     inititialize();
//   });
// } else {
//   // Polyfill wasn't needed, carry on
//   inititialize();
// }
inititialize();
