import React from 'react';
import { connect } from 'react-redux';
import { loadCancellations } from '../actions/cancellationsActions';
import { loadHistory } from '../actions/historyActions';
import CancellationItem from '../components/Cancellation/CancellationItem';
import CancellationItemDetails from '../components/Cancellation/CancellationItemDetails';
import { CSSTransitionGroup } from 'react-transition-group'


class Cancellation extends React.Component {
  componentWillMount() {
    if (this.props.showCancelButton === true) {
      this.props.dispatch(loadCancellations());
    } else {
      this.props.dispatch(loadHistory());
    }
  }
  
  componentDidMount() {
    if (this.props.showCancelButton === true) {
      const intervalid = setInterval(() => {
        this.props.dispatch(loadCancellations());
      }, 60000);
      this.setState({intervalid});
      } else {
        const intervalid = setInterval(() => {
          this.props.dispatch(loadHistory());
        }, 60000);
        this.setState({intervalid});
        }
      }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }


  render() {
    const {showCancelButton} = this.props;

    const itemlist = showCancelButton === true ? this.props.cancellations : this.props.history;

    const cancellations = itemlist.orderlist.map((order, index) => {
      const key1 = "d" + index;
      const key2 = "o" + index;
      const showdetails = itemlist.showdetails === order.orderid;
      const cd = showdetails ? <CancellationItemDetails order={order} key={key1} /> : null;
      return [<CancellationItem showCancelButton={showCancelButton} showdetails={showdetails} order={order} key={key2} />, cd];
    });

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={true}
        transitionEnterTimeout={500}
        transitionLeave={true}
        transitionLeaveTimeout={500}
        >
      <div className="container">
        <table className="table table-hover">
        <thead>
        <tr>
        <th style={{width:"20px"}}>&nbsp;</th>
        <th>Bestellnummer</th>
        <th>Datum/Uhrzeit</th>
        <th>Anzahl Tickets</th>
        <th>Bestellsumme</th>
        <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
          {cancellations}
        </tbody>
        </table>
      </div>
      </CSSTransitionGroup>
    );
  }
}

// export default class Cancellation extends React.Component {
//   render() {
//     return (
//       <div className="container">
//         <p>Seite: Stornierung</p>
//       </div>
//     );
//   }
// }

export default connect(
  state => ({ cancellations: state.cancellations, history: state.history }),
)(Cancellation);
