import React from 'react';

class FormFieldDropdown extends React.Component {

  onValueChange(event) {
    this.props.changeValue(this.props.data.id, parseInt(event.target.value, 10));
  }

  render() {
    const { id, name, value, options } = this.props.data;
    const htmloptions = options.map(option => <option value={option.id} key={option.id}>{ option.value }</option>);
    return (
      <div className="form-group valueselect">
        <label htmlFor={id} className="control-label">{name}</label>
        <select
          className="form-control"
          type="text"
          value={value}
          name={id}
          id={id}
          onChange={this.onValueChange.bind(this)}
        >
          { htmloptions }
        </select>
      </div>
    );
  }
}

export default FormFieldDropdown;
