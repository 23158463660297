import React from 'react';

class TotalsTableDailyRow extends React.Component {

  render() {
  	const {Day, OrderSum, PartnerId, PaymentFees, PointName, TicketQty, Username} = this.props.data;
    const {activecols} = this.props;

    return (
      <tr>
        <td>{Day}</td>
        <td>{TicketQty}</td>
        <td>{OrderSum}</td>
        <td>{PaymentFees}</td>
        {activecols.pointName && <td>{PointName}</td>}
        {activecols.username && <td>{Username}</td>}
      </tr>
    );
  }
}

export default TotalsTableDailyRow;
