import React from 'react';
import Currency from '../../tools/Currency';
import TicketSummaryForm from './TicketSummaryForm';
import TicketSummaryFormSeparator from "./TicketSummaryFormSeparator";

class TicketSummary extends React.Component {

  render() {
    var currentticketnumber = 0;
    const cmap = this.props.ticket.selectedconstraints.map((constraint) => {
      switch (constraint.viewtype) {
        case 'select-single':
          {
            const selected = constraint.formfields.find(
              formfield => (formfield.id === constraint.value));

            return (<tr key={constraint.constraint}>
              <th>{constraint.name}</th>
              <td>{selected.name}</td>
            </tr>);
          }
        case 'select-quantity':
          {
            return constraint.formfields.map((formfield) => {
              if (formfield.value > 0) {
                return (<tr key={formfield.id}>
                  <th>{formfield.name}</th>
                  <td>{formfield.value}x</td>
                </tr>);
              }
              return null;
            });
          }
        case 'form':
          {
            return constraint.formfields.map(formfield => {
              if (formfield.ticketdata && formfield.ticketdata.number && formfield.ticketdata.number !== currentticketnumber) {
                currentticketnumber = formfield.ticketdata.number;
                // console.log(currentticketnumber);
                return [<TicketSummaryFormSeparator currentticketnumber={currentticketnumber} formfield={formfield} />, <TicketSummaryForm formfield={formfield} />];
              } else {
                return <TicketSummaryForm formfield={formfield} />;
              }
            });
          }
        default: {
          return (<tr key={constraint.constraint}>
            <td colSpan="2" className="text-center">
          Renderer für Viewtype
          <em>{constraint.viewtype}</em> fehlt. Sascha fragen!
          </td>
          </tr>);
        }
      }
    });

    return (
      <table className="table ticketsummary">
        <tbody>
          {cmap}
          {(this.props.ticket.price  && !this.props.hideFullPrice) && <tr className="invoice-total">
            <th>Gesamtpreis</th>
            <td><Currency value={this.props.ticket.price} /></td>
          </tr>}
        </tbody>
      </table>
    );
  }
}
export default TicketSummary;

/*
{
    "buyable": true,
    "globalid": "a2b1c3d4",
    "price": 40,
    "selectedconstraints": [{
        "constraint": "option",
        "viewtype": "select-quantity",
        "description": "Bitte w\u00e4hlen Sie die Erm\u00e4\u00dfigungsstufen",
        "formfields": [{
            "id": 5,
            "name": "Erwachsene",
            "pricechange": 0,
            "value": 2
        }, {
            "id": 6,
            "name": "Erm\u00e4\u00dfigt",
            "pricechange": -5,
            "value": 0
        }]
    }]
}

{
    "buyable": true,
    "globalid": "a9b2c3d4",
    "price": 10,
    "selectedconstraints": [{
        "constraint": "tickettype",
        "viewtype": "select-single",
        "description": "Bitte w\u00e4hlen Sie den Tickettyp",
        "formfields": [{
            "id": 7,
            "name": "Normal",
            "pricechange": 0,
            "value": true
        }, {
            "id": 8,
            "name": "VIP (all inkl.)",
            "pricechange": 50,
            "value": false
        }]
    }]
}
*/
