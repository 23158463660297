import React from 'react';
import { connect } from 'react-redux';
import { updateEventList, setCurrentEvent } from '../actions/shopActions';
import EventListItem from './EventListItem';
import EventListSearch from './EventListSearch';
import Pager from "react-pager";
import FontAwesome from 'react-fontawesome';

class EventList extends React.Component {
  componentWillMount() {
    const query = this.props.eventlist.query;
    const page = this.props.eventlist.pagination.page;
    this.props.dispatch(updateEventList(query, page));
  }

  componentDidMount() {
    const intervalid = setInterval(() => {
      if (this.props.eventlist.preselect === null) {
        const query = this.props.eventlist.query;
        const page = this.props.eventlist.pagination.page;
        this.props.dispatch(updateEventList(query, page));
      }
    }, 30000);
    this.setState({intervalid});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }

  handlePageChanged(page) {
    const query = this.props.eventlist.query;
    this.props.dispatch(updateEventList(query, page));
  }

  handleQueryChanged(query) {
    this.props.dispatch(updateEventList(query, 0));
  }

  render() {
    const eventviewmode = this.props.eventviewmode;
    const events = this.props.eventlist.events;
    const mappedEvents = (eventviewmode === "show") ? events.map((event, index) => <EventListItem key={event.globalid + "_" + index} event={event} />) : <p>Eventliste unsichtbar.</p>;
    const eventlist = eventviewmode !== "remove" ? <div id="EventList">
      <EventListSearch onQueryChanged={this.handleQueryChanged.bind(this)} />
      {this.props.eventlist.hint && <div className="alert alert-warning" style={{marginTop:"15px"}}>{this.props.eventlist.hint}</div>}
      {mappedEvents}
      <Pager
				total={this.props.eventlist.pagination.totalpages}
				current={this.props.eventlist.pagination.page}
				visiblePages={4}
				titles={{ first: "erste", last: "letzte" }}
				className="pagination-sm"
				onPageChanged={this.handlePageChanged.bind(this)}
			/>
    </div> : null;

    return eventlist;
  }
}

export default connect(
  state => ({
    eventlist: state.eventlist,
  }),
)(EventList);
