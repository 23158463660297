import React from 'react';
import { connect } from 'react-redux';
import { unsetPDFFile } from '../actions/checkoutActions';
import FontAwesome from 'react-fontawesome';
import BodyClassName from 'react-body-classname';

class PDFOverlay extends React.Component {

  handlePDFClose() {
    this.props.dispatch(unsetPDFFile());
  }

  render() {

    return (this.props.checkout.pdfurl !== false) ?
    <BodyClassName className="noscroll">
    <div id="pdfcontainer">
        <div className="header text-right">
          <button onClick={this.handlePDFClose.bind(this)} className="btn btn-lg btn-block btn-success btn-pdfclose"> <FontAwesome name="close"/> PDF schließen</button>
        </div>
        <div className="pdfcontent">
          <object data={this.props.checkout.pdfurl} type="application/pdf" />
        </div>
     </div></BodyClassName>: null;
  }
}

export default connect(state => ({
  checkout: state.checkout,
}))(PDFOverlay);
