// import {
//   server
// } from '../app/serverapi';

// hier könnte ein Thunk ins Spiel kommen. Anstatt wie sonst häufig ein Objekt
// zurückzuliefern (bestehehend aus Type und Payload), liefere ich hier eine
// Funktion zurück.
// Die Funktion, die ich hier zurückliefere, bekommt, wenn sie später von der
// Middleware aufgerufen wird, zwei Funktionen als Parameter übergeben:
// - dispatch kann ich benutzen, um Actions zu dispatchen, die dann von Reducern
// aufgegriffen werden können.
// - getState liefert mir den aktuellen Status der App, sodass ich von dieser
// abhängig machen kann, was ich innerhalb der Funktion mache und welche
// Actions ich dann dispatche.
// export function loadEvent(globalid) {
//   return function blub(dispatch, getState) {
//     const {
//       eventcache
//     } = getState();
//     if (typeof eventcache[globalid] === 'undefined') {
//       dispatch({
//         type: 'LOAD_EVENT',
//         payload: server.get(`/event/${globalid}`),
//       }, );
//     }
//   };
// }


export function clearSearch() {
  return {
    type: 'CLEAR_EVENT_SEARCH',
  };
}

export function updateSearch(query) {
  return {
    type: 'UPDATE_EVENT_SEARCH',
    payload: query
  };
}
