import React from 'react';
import TicketSummaryFormDropdown from './TicketSummaryFormDropdown';
import TicketSummaryFormMultiSelect from './TicketSummaryFormMultiSelect';
import TicketSummaryFormSeparator from './TicketSummaryFormSeparator';
import TicketSummaryFormText from './TicketSummaryFormText';

class TicketSummaryForm extends React.Component {
  render() {
    const { formfield } = this.props;

    switch (formfield.type) {
      case 'text':
        return <TicketSummaryFormText formfield={formfield} />;
      // case 'separator':
      //   return <TicketSummaryFormSeparator formfield={formfield} />;
      case 'multiselect':
        return <TicketSummaryFormMultiSelect formfield={formfield} />;
      case 'dropdown':
        return <TicketSummaryFormDropdown formfield={formfield} />;
      default:
        return null;

    }
    //  return (
    //    <tr key={formfield.id}>
    //      <th>{formfield.name}</th>
    //      <td>{formfield.value}</td>
    //    </tr>
    //  )
  }
}

export default TicketSummaryForm;
