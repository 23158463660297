import React from 'react';

class FormFieldSeparator extends React.Component {

  render() {
    const { formfield } = this.props;
    if (formfield.description) {
      return <h4>{formfield.description}</h4>;
    }
    return <hr />;
  }
}

export default FormFieldSeparator;
