import React from 'react';
import EventDetails from './CancellationItemDetailsEvent';

class CancellationItemDetails extends React.Component {

  render() {

    const order = this.props.order;

    const isShipping = order.settings.shipping === true;
    const email = order.settings.email;
    const shippingAddressData = order.settings.shippingaddress ? order.settings.shippingaddress : false;
    const {orderid} = order;

    var shippingAddress;
    if (shippingAddressData !== false && isShipping) {
      const {city, firstname, lastname, number, salutation, street, zipcode} = shippingAddressData;
      shippingAddress =  <div>postalischer Versand an:<br />
        {salutation} {firstname} {lastname}<br />
        {street} {number}<br />
        {zipcode} {city}<br />
      </div>
    } else {
      shippingAddress = null;
    }
    const emailnotice = email !== undefined ? <span>E-Mail-Kopie an <br/> {email}</span> : <span>keine E-Mail-Kopie an den Kunden</span>

    const details = order.items.map((item, index) => <EventDetails key={index} item={item} orderid={orderid}/>);

    return <tr className="cancellation-details">
        <td colSpan="6">
          <div className="row">
            <div className="col-sm-6">
                {emailnotice}
            </div>
            <div className="col-sm-6">
            {shippingAddress}
            </div>
          </div>
          <div className="clearfix"></div>
          {details}
        </td>
    </tr>;
  }
}

export default CancellationItemDetails;
