import React from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Route } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'
import Popup from 'react-popup';
import FontAwesome from 'react-fontawesome';

import MainMenu from './MainMenu';
import PDFOverlay from '../components/PDFOverlay';
import LoginForm from './LoginForm';
import PageContent from './PageContent';
import { checkIfLoggedIn } from '../actions/authActions';
import { CSSTransitionGroup } from 'react-transition-group'
import Loader from "../components/Loader";


class Layout extends React.Component {

  componentWillMount() {
    this.props.dispatch(checkIfLoggedIn());
  }

  render() {
    const { booted, currentuser, view } = this.props.auth;
    if (booted === true) {
      if (currentuser === false) {
        document.location.hash = "";
        return (<LoginForm />);
      }

      const defaultRoute = view.tabs  === "stats-only" ? "/statistiken" : "/shop";

      return (
        <Router>
          <div className="Layout">
            <Loader />
          <Popup
              className="mm-popup"
              btnClass="mm-popup__btn"
              closeBtn={true}
              closeHtml={null}
              defaultOk="Ok"
              defaultCancel="Abbrechen"
              wildClasses={false} />

            {view.tabs !== "remove" && <Route path="/:currentcomponent" component={MainMenu} />}
            {this.props.auth.view.globalmessage && <div className="container-fluid">
              <div className="alert alert-warning text-center" role="alert" style={{padding:"4px 0"}}>
                {this.props.auth.view.globalmessage && <span><FontAwesome name="info-circle" /> {this.props.auth.view.globalmessage}</span>}
              </div>
            </div>}
              <PageContent defaultRoute={defaultRoute} />
            <PDFOverlay />
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="bottom-left"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar/>
          </div>
        </Router>
      );
    }
    return null;
  }
}

export default connect(
  state => ({ auth: state.auth}),
)(Layout);
