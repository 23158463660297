/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { loadTicket, resetTicket } from '../actions/shopActions';
import { setCurrentEvent } from '../actions/shopActions';


class Event extends React.Component {

  showEventDetails(eventid) {
    this.props.dispatch(setCurrentEvent(eventid));
    this.props.dispatch(loadTicket(eventid));

    // auch aktuelles Ticket löschen
    this.props.dispatch(resetTicket());
    // undo-History jedes mal leeren, wenn ein Event angeklickt wird
    this.props.dispatch(ActionCreators.clearHistory());
  }

  render() {
        /*
    end:'2018-10-25 22:00:00'
    id:'a1b2c3d4'
    name:'Vorabparty zur Garagenparty 2018'
    place:'Garage vor dem Haus, Straße 1, 12345 Hintertupfingen'
    remainingtickets:12
    start:'2018-10-25 18:00:00'
    */
    const { event } = this.props;
    return (
      <div
        key={event.globalid}
        className="event"
        onClick={this.showEventDetails.bind(this, event.globalid)}
      >
        <strong>{event.name}</strong><br />
        <small>{event.date}<br />
          {event.remainingtickets > 0 &&
            <span>Verfügbar: <b>{event.remainingtickets}</b><br /></span>
          }
        </small>
      </div>
    );
  }
}
export default connect()(Event);
