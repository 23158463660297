import React from 'react';
import Currency from '../../tools/Currency';

export class OrderCancelPrompt extends React.Component {
    render() {
      const {ordernumber, price} = this.props;
      return <span>Sind Sie sicher, dass Sie die Bestellung <b>{ordernumber}</b> zum Preis von <b><Currency value={price} /></b> dauerhaft stornieren wollen?</span>
    }
}

export class TicketCancelPrompt extends React.Component {
    render() {
      const {ticketcode, price} = this.props;
      return <span>Sind Sie sicher, dass Sie das Ticket <b>{ticketcode}</b> zum Preis von <b><Currency value={price} /></b> dauerhaft stornieren wollen?</span>
    }
}
