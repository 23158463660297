import React from "react";

class EinstellungenInfo extends React.Component {
  render() {
    const { name, point, admins } = this.props.partnerInfo;
    const profile = this.props.profile;

    const adminlist = admins.map((admin, key) =>
      <div key={"partnerAdmin" + key}>
        {admin.firstname} {admin.lastname} (<a href="mailto:{admin.email}">
          {admin.email}
        </a>)
      </div>
    );

    const pointadminlist = point.admins ? point.admins.map((admin, key) =>
      <div key={"pointAdmin" + key}>
        {admin.firstname} {admin.lastname} (<a href="mailto:{admin.email}">
          {admin.email}
        </a>)
      </div> 
    ) : "";

    return (
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Vorname</th>
            <td>
              {profile.firstname}
            </td>
          </tr>
          <tr>
            <th>Nachname</th>
            <td>
              {profile.lastname}
            </td>
          </tr>
          <tr>
            <th>E-Mail</th>
            <td>
              {profile.email}
            </td>
          </tr>
          <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>
          <tr>
            <th>VVK-Partner</th>
            <td>
              {name}
            </td>
          </tr>
          <tr>
            <th>Verwaltung durch</th>
            <td>
              {adminlist}
            </td>
          </tr>

          {this.props.role !== "partneradmin" && <tr>
            <td colSpan="2">&nbsp;</td>
          </tr>}

          {this.props.role !== "partneradmin" && <tr>
            <th>VVK-Stelle</th>
            <td>
              {point.name}
            </td></tr>}

          {this.props.role !== "partneradmin" && <tr>
            <th>Verwaltung durch</th>
            <td>
              {pointadminlist}
            </td>
          </tr>}
        </tbody>
      </table>
    );
  }
}

export default EinstellungenInfo;
