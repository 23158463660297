import React from 'react';
import EinstellungenInfo from "./EinstellungenInfo";
import EinstellungenPartnerInfos from "./EinstellungenPartnerInfos";
import { connect } from 'react-redux';
import {loadInfo, setPassword, updatePasswordField} from "../../actions/partnerInfoActions";

class Einstellungen extends React.Component {

  componentDidMount() {
    this.props.dispatch(loadInfo());
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.dispatch(setPassword(this.props.password));
  }

  onFieldChange(e) {
    const data = {key: e.target.id, value: e.target.value};
    this.props.dispatch(updatePasswordField(data));
  }

  render() {
    const {oldpassword, newpassword, newpassword2, fieldstate, message} = this.props.password;
    const msg = message !== "" ? <div className="alert alert-info" role="alert">{message}</div> : null;
    return (
      <div>
        <h3>persönliche Einstellungen</h3>
        <div className="row">
          <div className="col-xs-5">
            <h4>Passwort ändern</h4>
            {msg}
            <form onSubmit={this.onSubmit.bind(this)}>
            <div className="form-group">
              <label htmlFor="oldpw">altes Passwort</label>
              <input type="password" className="form-control" onChange={this.onFieldChange.bind(this)} value={oldpassword} id="oldpassword" />
            </div>
            <div className={"form-group " + fieldstate}>
              <label htmlFor="newpw1">neues Passwort</label>
              <input type="password" className="form-control" onChange={this.onFieldChange.bind(this)} value={newpassword} id="newpassword" />
            </div>
            <div className={"form-group " + fieldstate}>
              <label htmlFor="newpw2">Passwort wiederholen</label>
              <input type="password" className="form-control" onChange={this.onFieldChange.bind(this)} value={newpassword2} id="newpassword2" />
            </div>
            <button className="btn btn-success">speichern</button>
            </form>
          </div>
          <div className="col-xs-6 col-xs-offset-1">
            <div className="panel panel-default">
              <EinstellungenInfo partnerInfo={this.props.partnerInfo} role={this.props.profile.role} profile={this.props.profile} />
              { this.props.profile.role === "partneradmin" && <EinstellungenPartnerInfos billingaddress={this.props.partnerInfo.billingaddress} /> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  partnerInfo: state.partnerInfo,
  profile: state.auth.profile,
  password: state.password
}))(Einstellungen);
