/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React from 'react';
import { connect } from 'react-redux';
import { reservationToggleShowDetailsState } from '../actions/reservationActions';

class ReservationItemDetails extends React.Component {

  handleReservationToggle() {
    this.props.dispatch(reservationToggleShowDetailsState(this.props.reservation.reservationid));
  }

  render() {
    // console.log(this.props);
    const caretClass = (!this.props.isopen) ? 'fa fa-fw fa-caret-right' : 'fa fa-fw fa-caret-down';
    const line = (<span><i className={caretClass} aria-hidden="true" />&nbsp;
      { this.props.reservation.quantity }&nbsp;| &nbsp;
      {this.props.event.name}</span>);
    // line = (this.props.isopen) ? {line}</b> : line;

    return (
      <div onClick={this.handleReservationToggle.bind(this)}>
        {line}
      </div>
    );
  }
}

export default connect()(ReservationItemDetails);
