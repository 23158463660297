import React from 'react';
import TicketDetails from './CancellationItemDetailsEventTicket';
import TicketSummary from '../TicketSummary/';

class CancellationItemDetailsEvent extends React.Component {

  render() {
    const {eventname} = this.props.item.item;
    const {orderid} = this.props;

    const ticketdetails = this.props.item.tickets.map((ticket, index) => <TicketDetails ticket={ticket} key={index} orderid={orderid} />);
    return <div style={{borderTop:"3px solid #ddd"}}>
      <h3>Event: {eventname}</h3>
      <TicketSummary ticket={this.props.item.item} />
      <table className="table table-ticketdetails">
      <tbody>
        {ticketdetails}
      </tbody>
      </table>
    </div>;
  }
}

export default CancellationItemDetailsEvent;
