import React from 'react';
import { ActionCreators } from 'redux-undo';
import * as FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';

class UndoButton extends React.Component {

  undo() {
    this.props.dispatch(ActionCreators.undo());
  }

  render() {
    return (this.props.undoAvailable) ? <button
      className="btn-block btn btn-info"
      onClick={this.undo.bind(this)}
    >
      <FontAwesome
        name="angle-double-left"
      />&nbsp;zurück
      </button> :

    <button
      className="btn-block btn btn-default"
      disabled={true}
    >
      <FontAwesome
        name="angle-double-left"
      />&nbsp;zurück
      </button>;
  }
}
export default connect()(UndoButton);
