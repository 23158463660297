import React from 'react';
import TotalsTableDaily from "./TotalsTableDaily";
import TotalsTableWeekly from "./TotalsTableWeekly";
import TotalsTableMonthly from "./TotalsTableMonthly";
import TotalsTableYearly from "./TotalsTableYearly";

class TotalsTable extends React.Component {

  render() {
    const {interval, activecols} = this.props;
        switch(interval) {
            case "daily": 
                return <TotalsTableDaily activecols={activecols} rows={this.props.rows} />;
            case "weekly": 
                return <TotalsTableWeekly activecols={activecols} rows={this.props.rows} />;
            case "monthly": 
                return <TotalsTableMonthly activecols={activecols} rows={this.props.rows} />;
            case "yearly": 
                return <TotalsTableYearly activecols={activecols} rows={this.props.rows} />;

        }
        return <p>Unbekannter Intervall {this.props.interval}</p>;
  }
}

export default TotalsTable;
