import axios from "axios";
import { apiurl } from "./config";
import { handleInvalidedSession } from "../actions/authActions";
import posstore from "../app/store";
import { toastr } from "react-redux-toastr";
import { AxiosStatus } from "axios-status";

// import store from './store';

// store.subscribe(listener)
//
// function select(state) {
//   return state.auth.authkey
// }
//
// function listener() {
//   let token = select(store.getState())
//   // console.log(token);
//   axios.defaults.headers.common['X-URL-Token'] = token;
// }

// configuration
// console.log("configure axios");

// const pattern = new RegExp('^#/([a-zA-Z0-9]*)');
// const urltoken = pattern.exec(window.location.hash);
// const vvkurltoken = urltoken !== null && urltoken.length === 2 ? urltoken[1] : false;

const requestConfig = {
  baseURL: apiurl(),
  timeout: 60000,
  headers: {
    "Content-Type": "application/json"
  }
};
// console.log(requestConfig)

const axiosStatus = new AxiosStatus();

const authserver = axios.create(requestConfig);
const server = axios.create({
  ...requestConfig,
  headers: {
    "Content-Type": "application/json"
    // 'X-Csrf-Token': "dummy",
  },
  withCredentials: true
});

axiosStatus.register(server);

axiosStatus.on('busy', function(active) {
  posstore.dispatch({
    type: 'SET_LOADER_STATE',
    payload: active,
  });
});



// authserver.interceptors.response.use(function(response) {
//   return response;
// }, function(error) {
//   posstore.dispatch(handleInvalidedSession());
//   return Promise.reject(error);
// });



// Add a request interceptor
server.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    // console.log(error);
    return Promise.reject(error);
  }
);

server.interceptors.response.use(
  function(response) {
    // console.log(response);
    return response;
  },
  function(error) {
    if (error.response) {
      // console.log(error.response);
      const { status, data } = error.response;
      const message = (data && data.message) ? data.message : "Unbekannter Fehler aufgetreten. Lutz Meier fragen."

      console.log(status, message);

      // Fehlermeldung anzeigen
      switch (status) {
          case 200:
            toastr.success(
              "Erfolg",
              message
            );
            break;

          case 400:
          case 401:
          case 403:
            toastr.success(
              "Fehler",
              message
            );
            break;
      }

      switch (status) {
        case 400:
          break;
        case 401:
          posstore.dispatch(handleInvalidedSession());
        case 403:
          break;
        case 404:
          break;
      }
    } else {
      if (
        error.message.indexOf("timeout") > -1 &&
        error.message.indexOf("exceeded") > -1
      ) {
        toastr.error(
          "Verbindungsproblem",
          "Die Internetverbindung ist zu schlecht. Die Anfrage wurde nicht verarbeitet."
        );
      }
    }
    return Promise.reject(error);
  }
);

export { server, authserver };
