import React from 'react';
import { ActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import FormFieldInput from './FormFieldInput';
import FormFieldDropdown from './FormFieldDropdown';
import FormFieldMultiselect from './FormFieldMultiselect';
import FormFieldSeparator from './FormFieldSeparator';
import UndoButton from './UndoButton';

class SelectForm extends React.Component {

  submitConstraint() {
    this.props.commitConstraint();
  }

  undo() {
    this.props.dispatch(ActionCreators.undo());
  }

  handleInputChange(event) {
    // console.log(event.target.value);
    this.props.changeValue(parseInt(event.target.id, 10), event.target.value);
  }

  render() {
    const formfieldmap = this.props.formFields.map((formfield) => {
      switch (formfield.type) {
        case 'text':
          {
            return (<FormFieldInput
              price={this.props.price}
              changeValue={this.props.changeValue}
              key={formfield.id}
              data={formfield}
            />);
          }
        case 'dropdown':
          {
            return (<FormFieldDropdown
              price={this.props.price}
              changeValue={this.props.changeValue}
              key={formfield.id}
              data={formfield}
            />);
          }
        case 'separator':
          {
            return (<FormFieldSeparator formfield={formfield} key={formfield.id} />);
          }
        case 'multiselect':
          {
            return (<FormFieldMultiselect
              price={this.props.price}
              switchValue={this.props.switchValue}
              key={formfield.id}
              data={formfield}
            />);
          }
        default: return null;
      }
    });

    return (
      <div id="SelectForm">
        <p>{this.props.description}</p>
        {(this.props.error) ? <div className="alert alert-warning">{this.props.error}</div> : ''}
        {formfieldmap}
        <div className="row">
          <div className="col-xs-4">
            <UndoButton undoAvailable={this.props.undoAvailable} />
          </div>
          <div className="col-xs-8">
            <button
              className="btn-block btn btn-success"
              onClick={this.submitConstraint.bind(this)}
            >
              weiter
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect()(SelectForm);


/*
    "nextconstraint": {
        "constraint": "name",
        "viewtype": "form",
        "description": "Bitte geben Sie den Namen f\u00fcr die Fahrtberechtigung ein",
        "formfields": [{
            "id": 123,
            "name": "Vorname",
            "type": "text",
            "value": ""
        }, {
            "id": 124,
            "name": "Nachname",
            "type": "text",
            "value": ""
        }]
    },
*/
