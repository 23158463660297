import React from "react";
import TotalsTableMonthlyRow from "./TotalsTableMonthlyRow";

class TotalsTableMonthly extends React.Component {
    render() {

        const {activecols} = this.props;
        
        const rows = this.props.rows.map((item, index) =>
            <TotalsTableMonthlyRow activecols={activecols} key={"ttm" + index} data={item} />
        , this);
        
        return (
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>von</th>
                        <th>bis</th>
                        <th>Ticket-Anzahl</th>
                        <th>Ticket-Umsatz</th>
                        <th>VVK-Gebühren</th>
                        {activecols.pointName && <th>VVK-Stelle</th>}
                        {activecols.username && <th>Verkäufer</th>}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}

export default TotalsTableMonthly;
