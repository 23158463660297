import React from 'react';

class FormField extends React.Component {

  onValueChange(event) {
    this.props.changeValue(this.props.data.id, event.target.value);
  }

  render() {
    const { id, name, value, required } = this.props.data;
    const req = required ? "*" : "";
    return (
      <div className="form-group valueselect">
        <label htmlFor={id} className="control-label">{name}{req}</label>
        <input
          className="form-control"
          type="text"
          name={id}
          id={id}
          value={value}
          onChange={this.onValueChange.bind(this)}
        />
      </div>
    );
  }
}

export default FormField;
