import React from 'react';
import { connect } from 'react-redux';
import { tryLogin } from '../actions/authActions';
import { CSSTransitionGroup } from 'react-transition-group'

class LoginForm extends React.Component {

  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
    };
  }

  onChangeUsername(event) {
    const username = event.target.value;
    this.setState({ username });
  }

  onChangePassword(event) {
    const password = event.target.value;
    this.setState({ password });
  }

  tryLogin(e) {
    e.preventDefault();
    this.props.dispatch(tryLogin(this.state.username, this.state.password));
    // return false;
  }

  render() {
    const errormessage = this.props.login.errormessage
    ? <div className="alert alert-danger" role="alert">{this.props.login.errormessage}</div>
    : null;

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={true}
        transitionEnterTimeout={500}
        transitionLeave={true}
        transitionLeaveTimeout={500}
        >
<div className="row" id="LoginForm">
        <div className="col-xs-6 col-xs-offset-2 col-sm-4 col-sm-offset-4">

          <div className="panel panel-default">
            <div className="panel-heading">
              <h3 className="panel-title">tivents|VVK Login</h3>
            </div>
            <div className="panel-body">
              {errormessage}
              <form onSubmit={this.tryLogin.bind(this)}>
              <div className="form-group">
                <label htmlFor="username">Nutzername:</label>
                <input
                  className="form-control"
                  id="username"
                  type="text"
                  onChange={this.onChangeUsername.bind(this)}
                  value={this.state.username}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Passwort:</label>
                <input
                  className="form-control"
                  id="password"
                  type="password"
                  onChange={this.onChangePassword.bind(this)} value={this.state.password}
                />
              </div>
              <button
                className="btn btn-block btn-success" >absenden</button>
                </form>
            </div>
          </div>
        </div>
      </div>
      </CSSTransitionGroup>
    );
  }
}

export default connect(
  state => ({
    login: state.auth,
  }),
)(LoginForm);
