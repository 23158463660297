import React from 'react';

class TicketSummaryFormText extends React.Component {
  render() {
    const { formfield } = this.props;
    // console.log(formfield);
    return (
      <tr key={formfield.id}>
        <th>{formfield.name}</th>
        <td>{formfield.value}</td>
      </tr>
    );
  }
}

export default TicketSummaryFormText;
