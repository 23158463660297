import React from 'react';
import Currency from '../tools/Currency';

class FormField extends React.Component {

  onAmountChange(event) {
    this.props.changeAmount(this.props.data.id, parseInt(event.target.value, 10));
  }

  increaseAmount() {
    this.props.increaseAmount(this.props.data.id);
  }

  decreaseAmount() {
    this.props.decreaseAmount(this.props.data.id);
  }

  render() {
    const { id, name, price, value } = this.props.data;
    return (
      <tr>
        <td>
          <label htmlFor={id} className="control-label">{name}</label>
        </td>
        <td className="text-right"><Currency value={price} /></td>
        <td style={{ width: '100px' }}>
          <div className="input-group">
            <span className="input-group-btn">
              <button className="btn btn-default btn-sm" onClick={this.decreaseAmount.bind(this)} type="button">&minus;</button>
            </span>
            <input style={{ minWidth: '40px' }} className="text-right input-sm form-control pull-left" type="number" name={id} id={id} value={value} onChange={this.onAmountChange.bind(this)} />
            <span className="input-group-btn">
              <button className="btn btn-default btn-sm" onClick={this.increaseAmount.bind(this)} type="button">+</button>
            </span>
          </div>

        </td>
      </tr>
    );
  }
}

export default FormField;
