import React from 'react';
import { cancelTicket, printTicket } from '../../actions/cancellationsActions';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import Currency from '../../tools/Currency';
// import OrderCancelPopup from './CancellationPopup';
import { TicketCancelPrompt } from './CancellationPopupPrompts';
import Popup from 'react-popup';


const stornoPopup = (prompt, dispatch, ticketcode, orderid) => Popup.register({
    title: "Bestätigung erforderlich!",
    content: prompt,
    buttons: {
        left: [{
          text: 'abbrechen',
          className: 'notice',
          action: () => {
            Popup.close();
          }
        }],
        right: [{
          text: 'Ja, ich möchte stornieren!',
          className: 'danger',
          action: () => {
            dispatch(cancelTicket(ticketcode, orderid));
            Popup.close();
          }
        }],
    }
});

class CancellationItemDetailsEventTicket extends React.Component {

  onCancelTicket() {
    Popup.queue(stornoPopup(<TicketCancelPrompt ticketcode={this.props.ticket.ticketcode} price={this.props.ticket.price} />, this.props.dispatch, this.props.ticket.ticketcode, this.props.orderid));
  }

  onPrintTicket() {
    this.props.dispatch(printTicket(this.props.ticket.ticketcode, this.props.orderid));
  }

  render() {
    const {shortdescription, ticketcode, price} = this.props.ticket;
    return <tr>
        <td>{shortdescription}</td>
        <td style={{width: "150px"}}>Preis: {price && <Currency value={price} />}<br/>Code: {ticketcode}</td>
        <td className="minibuttons text-right" style={{width: "165px"}}>
          <button className="btn btn-xs btn-info" onClick={this.onPrintTicket.bind(this)}><FontAwesome name="print"/> Ticket drucken</button>
          {/*<button className="btn btn-xs btn-danger" onClick={this.onCancelTicket.bind(this)}><FontAwesome name="times"/> Ticket stornieren</button>*/}
          </td>
      </tr>;
  }
}

export default connect()(CancellationItemDetailsEventTicket);
