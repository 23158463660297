import React from 'react';
import Toggle from 'react-toggle';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as R from 'ramda';
import ListRow from "./ListRow";
import Modal from "./Modal";
import { loadPoints, addPoint } from "../../actions/pointsActions";

class List extends React.Component {

  componentDidMount() {
    this.props.dispatch(loadPoints());
  }

  startCreating() {
    this.props.dispatch(addPoint());
  }


  render() {
    const points = this.props.points;
    const editpoint = this.props.editpoint;

    const activepointslist = points.filter((point) => point.status === 1).map((point) => <ListRow key={"point" + point.pointid} point={point} />);
    const inactivepointslist = points.filter((point) => point.status !== 1).map((point) => <ListRow key={"point" + point.pointid} point={point} />);

    return (
      <div>
        <h3>Meine VVK-Stellen</h3>
        <Button className="btn btn-info" onClick={this.startCreating.bind(this)} ><FontAwesome name="plus-circle" /> VVK-Stelle hinzufügen</Button>
        <h4>aktive VVK-Stellen</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nachname</th>
              <th>Adresse</th>
              <th>Verwalter</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {activepointslist}
          </tbody>
        </table>


        <h4>deaktivierte VVK-Stellen</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nachname</th>
              <th>Adresse</th>
              <th>Verwalter</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {inactivepointslist}
          </tbody>
        </table>
        <Modal editpoint={editpoint}/>
      </div>
    );
  }
}

export default connect(state => ({
  points: state.points.pointlist,
  editpoint: state.points.editpoint
}))(List);
