import React from "react";
import TotalsTableDailyRow from "./TotalsTableDailyRow";

class TotalsTableDaily extends React.Component {
    render() {
        
        const {activecols} = this.props;

        const rows = this.props.rows.map((item, index) =>
            <TotalsTableDailyRow activecols={activecols} key={"ttdaily"+index} data={item} />
        , this);
        return (
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Ticket-Anzahl</th>
                        <th>Ticket-Umsatz</th>
                        <th>VVK-Gebühren</th>
                        {activecols.pointName && <th>VVK-Stelle</th>}
                        {activecols.username && <th>Verkäufer</th>}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}

export default TotalsTableDaily;
