import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducers from '../reducers';
import {env} from './env';

const middleware = (env === 'production')
    ? applyMiddleware(promise(), thunk)
    : applyMiddleware(promise(), thunk, logger);

export default createStore(reducers, middleware);
