import React from 'react';
import { connect } from 'react-redux';
import { cancelOrder, printOrder, switchDetails } from '../../actions/cancellationsActions';
import { historyPrintOrder, historySwitchDetails } from '../../actions/historyActions';
import Currency from '../../tools/Currency';
import FontAwesome from 'react-fontawesome';
import Popup from 'react-popup';
import { OrderCancelPrompt } from './CancellationPopupPrompts';

const stornoPopup = (prompt, dispatch, orderid) => Popup.register({
    title: "Bestätigung erforderlich!",
    content: prompt,
    buttons: {
        left: [{
          text: 'abbrechen',
          className: 'notice',
          action: () => {
            Popup.close();
          }
        }],
        right: [{
          text: 'Ja, ich möchte stornieren!',
          className: 'danger',
          action: () => {
            dispatch(cancelOrder(orderid));
            Popup.close();
          }
        }],
    }
});


class CancellationItem extends React.Component {

  onCancelOrder(e) {
    Popup.queue(stornoPopup(<OrderCancelPrompt ordernumber={this.props.order.ordernumber} price={this.props.order.totalprice} />, this.props.dispatch, this.props.order.orderid));
  }

  onPrintOrder(e) {
    if (this.props.showCancelButton === true) {
      this.props.dispatch(printOrder(this.props.order.orderid));
    } else {
      this.props.dispatch(historyPrintOrder(this.props.order.orderid));
    }
  }

  switchDetails() {
    if (this.props.showCancelButton === true) {
      this.props.dispatch(switchDetails(this.props.order.orderid));
    } else {
      this.props.dispatch(historySwitchDetails(this.props.order.orderid));
    }
  }

  render() {
    const {ordernumber, orderdate, totalquantity, totalprice} = this.props.order;
    const showdetails = this.props.showdetails;
    
    const caretClass = showdetails ? 'fa fa-fw fa-caret-down' : 'fa fa-fw fa-caret-right';
    const trClass = showdetails ? 'active-row' : '';

    return <tr className={trClass}>
        <td onClick={this.switchDetails.bind(this)} style={{width:"20px", cursor: "pointer"}}><i className={caretClass} aria-hidden="true" /></td>
        <td onClick={this.switchDetails.bind(this)} style={{cursor: "pointer"}}>{ordernumber}</td>
        <td onClick={this.switchDetails.bind(this)} style={{cursor: "pointer"}}>{orderdate}</td>
        <td onClick={this.switchDetails.bind(this)} style={{cursor: "pointer"}}>{totalquantity}</td>
        <td onClick={this.switchDetails.bind(this)} style={{cursor: "pointer"}}><Currency value={totalprice} /></td>
        <td className="minibuttons text-right" style={{width: "165px"}}>
          {this.props.showCancelButton && <button className="btn btn-danger btn-xs" data-orderid="1" onClick={this.onCancelOrder.bind(this)}><FontAwesome name="times"/> Bestellung stornieren</button>}
          <button className="btn btn-info btn-xs" data-orderid="1" onClick={this.onPrintOrder.bind(this)}><FontAwesome name="print"/> Bestellung drucken</button>
        </td>
    </tr>;
  }
}

export default connect()(CancellationItem);
