import * as React from "react";
import BtnGroupButton from "./BtnGroupButton";

class IntervalSelector extends React.Component {

	render() {
		const intervalButtons = [
			{ name: "daily", title: "täglich" },
			{ name: "weekly", title: "wöchentlich" },
			{ name: "monthly", title: "monatlich" },
			{ name: "yearly", title: "jährlich" }
		].map(interval =>
			<BtnGroupButton
				key={"interval" + interval.name}
				onChange={this.props.onChange}
				active={this.props.interval == interval.name}
				data={interval}
			/>
		);

		return (
			<div
				className="btn-group"
				role="group"
				aria-label="Interval auswählen"
			>
				{intervalButtons}
			</div>
		);
	}
}

export default IntervalSelector;
