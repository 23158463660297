/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import React from 'react';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { setShipping } from '../actions/reservationActions';
import '../../node_modules/react-toggle/style.css';

class ShippingSwitch extends React.Component {

  shippingChangeHandler(event) {
    this.props.dispatch(setShipping(event.target.checked));
  }

  render() {
    // const shippingswitchOff = (this.props.state === false) ? 'checked' : '';
    // const shippingswitchOn = (this.props.state === true) ? 'checked' : '';

    return (
      <div className="row">
        <div className="col-xs-9"><label style={{ display: 'block' }} htmlFor="ticketsPerPost">Tickets als Hardcover per Post zusenden (1,90€ pro Ticket)</label></div>
        <div className="col-xs-3 text-right">
          <Toggle
            id="ticketsPerPost"
            checked={this.props.reservationsettings.shipping}
            onChange={this.shippingChangeHandler.bind(this)}
          />
        </div>
      </div>
    );
  }
}


export default connect(
  state => ({
    reservationsettings: state.reservationsettings,
  }),
)(ShippingSwitch);
