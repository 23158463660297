import * as React from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";

class DateRangeSelector extends React.Component {
	componentWillMount() {
		this.setState({ focusedInput: null, startDate: this.props.startDate, endDate: this.props.endDate });
	}

	onDateRangeChange(interval) {
		this.setState(interval);
	}

	onFocusChange(focusedInput) {
		this.setState({ focusedInput });
		if (focusedInput === null) {
			this.props.onChange({
				startDate: this.state.startDate,
				endDate: this.state.endDate
			});
		}
	}

	render() {
		const { startDate, endDate } = this.state;
		return (
			<DateRangePicker
				startDate={startDate} // momentPropTypes.momentObj or null,
				endDate={endDate} // momentPropTypes.momentObj or null,
				isOutsideRange={() => false}
				minimumNights={0}
				keepOpenOnDateSelect
				focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
				onFocusChange={this.onFocusChange.bind(this)} // PropTypes.func.isRequired,
				onDatesChange={({ startDate, endDate }) =>
					this.onDateRangeChange({ startDate, endDate })} // PropTypes.func.isRequired,
			/>
		);
	}
}
//
//

export default DateRangeSelector;
