import * as React from "react";
import OrdersTableRow from "./OrdersTableRow";

class OrdersTable extends React.Component {
    render() {
        const {activecols} = this.props;
        const rows = this.props.rows.map((row, index) =>
            <OrdersTableRow activecols={activecols} key={"orders" + index} row={row} />
        , this);
        return (
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Bestellnummer</th>
                        <th>Bestelldatum</th>
                        <th>Eventname</th>
                        <th>Event-ID</th>
                        <th>Ticketoptionen</th>
                        <th>Bestellsumme</th>
                        <th>VVK-Gebühren</th>
                        <th>Ticketcode</th>
                        {activecols.pointName && <th>VVK-Stelle</th>}
                        {activecols.username && <th>Verkäufer</th>}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}

export default OrdersTable;
