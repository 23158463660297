import React from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import EventList from '../components/EventList';
import Questions from '../components/Questions';
import Reservations from '../components/Reservations';
import Checkout from '../components/Checkout';
import CheckoutModal from '../components/CheckoutModal';
import { CSSTransitionGroup } from 'react-transition-group'

import {
    loadReservations,
    reserveTicket,
    changeFormFieldAmount,
    increaseFormFieldAmount,
    decreaseFormFieldAmount,
    changeFormFieldValue,
    switchFormFieldValue,
    changeFormFieldSelected,
    commitConstraint,
} from '../actions/shopActions';
import {
  loadReservationSettings,
} from '../actions/reservationActions';


class Shop extends React.Component {

  componentWillMount() {
    this.props.dispatch(loadReservations());
    this.props.dispatch(loadReservationSettings());
  }

  onCommitConstraint() {
    this.props.dispatch(commitConstraint(this.props.ticket));
  }

  onReserve() {
    this.props.dispatch(reserveTicket(this.props.ticket));
  }

  onUndo() {
    this.props.dispatch(ActionCreators.undo());
  }

  changeSelected(id) {
    this.props.dispatch(changeFormFieldSelected(id));
  }

  changeValue(id, value) {
    this.props.dispatch(changeFormFieldValue(id, value));
  }

  switchValue(id, value) {
    this.props.dispatch(switchFormFieldValue(id, value));
  }

  changeAmount(id, value) {
    this.props.dispatch(changeFormFieldAmount(id, value));
  }

  increaseAmount(id) {
    this.props.dispatch(increaseFormFieldAmount(id));
  }

  decreaseAmount(id) {
    this.props.dispatch(decreaseFormFieldAmount(id));
  }

  render() {
    const ticket = this.props.ticket;
    let pagecontent;
    // console.log(this.props.ticket);
    if (!ticket.loaded) {

      pagecontent = <div className="alert alert-info">Bitte Veranstaltung wählen.</div>;
    } else {
      pagecontent = (ticket.buyable)
          ? (<Checkout
            onReserve={this.onReserve.bind(this)}
            ticket={ticket}
          />)
          : (<Questions
            changeValue={this.changeValue.bind(this)}
            switchValue={this.switchValue.bind(this)}
            changeAmount={this.changeAmount.bind(this)}
            increaseAmount={this.increaseAmount.bind(this)}
            decreaseAmount={this.decreaseAmount.bind(this)}
            changeSelected={this.changeSelected.bind(this)}
            commitConstraint={this.onCommitConstraint.bind(this)}
            ticket={ticket}
            price={ticket.price}
            question={ticket.nextconstraint}
          />);
    }


    const openlist = this.props.reservations.items.map(reservation => ({
      globalid: reservation.globalid,
      open: !!(reservation.open && reservation.open === true),
    }));

    const eventviewmode = this.props.view.event;


    const contentclassname = (eventviewmode === "remove") ? "col-sm-6 col-xs-12" : "col-sm-5 col-xs-12";
    const reservationsclassname = (eventviewmode === "remove") ? "col-sm-6 col-xs-12" : "col-sm-4 col-xs-12";

    const eventlist = eventviewmode !== "remove" ? <div className="col-sm-3 col-xs-12">
      <EventList eventviewmode={eventviewmode} />
    </div> : <EventList eventviewmode={eventviewmode} />

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={true}
        transitionEnterTimeout={500}
        transitionLeave={true}
        transitionLeaveTimeout={500}
        >
      <div>
        <div className="container-fluid" id="Shop">
          <div className="row">
            {eventlist}
            <div className={contentclassname}>
              {pagecontent}
            </div>
            <div className={reservationsclassname}>
              <Reservations openlist={openlist} />
            </div>
          </div>
        </div>
        <CheckoutModal />
      </div>
      </CSSTransitionGroup>
    );
  }
}

export default connect(
  state => ({
    view: state.auth.view,
    ticket: state.ticket.present,
    reservations: state.reservations,
    eventlist: state.eventlist,

  }),
)(Shop);
