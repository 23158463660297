import React from 'react';
import { ActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import FormFieldRadioSelect from './FormFieldRadioSelect';
import UndoButton from './UndoButton';

class SelectSingle extends React.Component {

  changeAndSubmitSelected(id) {
    this.props.changeSelected(id);
    this.props.commitConstraint();
  }

  undo() {
    this.props.dispatch(ActionCreators.undo());
  }

  render() {
    const formfieldmap = this.props.formFields.map(formfield =>
      <FormFieldRadioSelect
        price={this.props.price}
        remainingtickets={formfield.remainingtickets}
        changeSelected={this.changeAndSubmitSelected.bind(this)}
        key={formfield.id}
        data={formfield}
        currentvalue={this.props.value}
      />);

    return (
      <div id="SelectSingle">
        <p>{this.props.description}</p>
        {(this.props.error) ? <div className="alert alert-warning">{this.props.error}</div> : ''}
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Option</th>
              <th className="text-right">Verfügbar</th>
              <th className="text-right">Preis</th>
            </tr>
          </thead>
          <tbody>
            {formfieldmap}
          </tbody>
        </table>
        <div className="row">
          <div className="col-xs-4">
            <UndoButton undoAvailable={this.props.undoAvailable} />
          </div>
          <div className="col-xs-8">
            {/* <button
              className="btn-block btn btn-success"
              onClick={this.submitConstraint.bind(this)}
            >
              weiter&nbsp;<FontAwesome name="angle-double-right" />
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(SelectSingle);
