import * as React from "react";
import { connect } from "react-redux";
import { CSSTransitionGroup } from 'react-transition-group';
import TypeSelector from "./ViewConfig/TypeSelector";
import DateRangeSelector from "./ViewConfig/DateRangeSelector";
import IntervalSelector from "./ViewConfig/IntervalSelector";
import * as R from 'ramda';
import Pager from "react-pager";
import {
	changeDateRange,
	changeInterval,
	changeType,
	changePage,
	loadStats,
	requestSendPerMail
} from "../../actions/statsActions";

import TotalsTable from "./Tables/TotalsTable";
import OrdersTable from "./Tables/OrdersTable";

class Stats extends React.Component {
	/**
	 *
	 * Problem: wenn ich die Komponente starte, will ich bei componentWillMount()
	 * den Event zum Laden der Statstiken abfeuern. Das heißt, dass ich in diesem 
	 * Moment schon die Daten der drei Subkomponenten brauche.
	 *
	 * Ich muss wohl doch im Reducer einen sinnvollen Initialstate festlegen und den
	 * dann hier in die Komponenten injizieren. 
	 * 
	 * TypeSelector und IntervalSelector sind dann halt doch Stateless, da sie eine 
	 * Statusänderung direkt zurückmelden, ich die hier abfange und wieder in den Store
	 * schreibe. 
	 *
	 * Bei Daterange ist es genauso, bloß dass der einen internen State über die Auswahl führt
	 * und den Change-Event erst feuert, wenn man das Popup schließt.
	 *
	 * UNGELÖST UNGELÖST UNGELÖST UNGELÖST UNGELÖST:
	 * Lese ich hier den Store aus und generiere daraus eine neue Action (wie bisher), die 
	 * dann die entsprechenden Statistiken nachlädt?
	 *
	 * Oder gibt es doch eine Möglichkeit, dass ich hier eine Action zum Ändern von
	 * DateRange/Type/Interval feure und die dann den Store mit den Settings updated 
	 * dann gleichzeitig aufgrund des neuen Store-States auch die neuen Statistiken holt?
	 * 
	 */
	componentDidMount() {
		this.props.dispatch(loadStats());
	}

	onDateRangeChange(dateRange) {
		if (dateRange.startDate.format("YYYY-MM-DD") !== this.props.stats.config.start.format("YYYY-MM-DD") ||
			dateRange.endDate.format("YYYY-MM-DD") !== this.props.stats.config.end.format("YYYY-MM-DD")) {
			this.props.dispatch(changeDateRange(dateRange));
		}
	}

	onRequestSendPerMail() {
		this.props.dispatch(requestSendPerMail());
	}

	onIntervalChange(interval) {
		this.props.dispatch(changeInterval(interval));
	}

	onTypeChange(type) {
		this.props.dispatch(changeType(type));
	}

	onPageChange(page) {
		this.props.dispatch(changePage(page));
	}

	render() {
		const { config } = this.props.stats;

		var table;
		var pagination;
		var rows;

		const activecols = {
			pointName: this.props.profile.role === "partneradmin",
			username: this.props.profile.role === "pointadmin" || this.props.profile.role === "partneradmin"
		}
		// const showPointName = this.props.profile.role === "partneradmin";
		// const showUsername = this.props.profile.role === "pointadmin";
		// const showUsername = true;

		switch (config.type) {
			case "totals": 
				rows = this.props.stats.totals;
				break;
			case "orders": 
				rows = this.props.stats.orders;
				break;
		}

		if (!R.isEmpty(rows)) 
		{
			switch (config.type) {
				case "orders": {
					table = <OrdersTable activecols={activecols} rows={rows} />;
					pagination = this.props.stats.paginationorders;
					break;
				}
				case "totals": {
					table = (
						<TotalsTable
							interval={config.interval}
							rows={rows}
							activecols={activecols}
						/>
					);
					pagination = this.props.stats.paginationtotals;
					break;
				}
			}
		} else {
			table = <div className="container alert alert-info">Für diese Auswahl wurde kein Ergebnis gefunden.</div>;
		}


		return (
			   <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={true}
        transitionEnterTimeout={500}
        transitionLeave={true}
        transitionLeaveTimeout={500}
        >
				<div className="container">
					<div className="row">
						<div className="col-sm-4">
							Statistiken zu:<br />
							<TypeSelector
								type={config.type}
								onChange={this.onTypeChange.bind(this)}
							/>
						</div>
						<div className="col-sm-4">
							Datumsbereich:<br />
							<DateRangeSelector
								startDate={config.start}
								endDate={config.end}
								onChange={this.onDateRangeChange.bind(this)}
							/>
						</div>
						<div className="col-sm-4">
							{config.type === "totals" &&
								<span>
									Verkaufsansicht:<br />
									<IntervalSelector
										interval={config.interval}
										onChange={this.onIntervalChange.bind(
											this
										)}
									/>
								</span>}
							{config.type === "orders" &&
								<span>
									<br />
									<button onClick={this.onRequestSendPerMail.bind(this)} className="btn btn-primary">Exportieren und per Mail senden</button>
								</span>}

						</div>
					</div>
				</div>
				<div className="container-fluid">
					<br />
					{table}

					{pagination &&
						<div className="text-center">
							<Pager
								total={pagination.totalpages}
								current={pagination.page}
								visiblePages={8}
								titles={{ first: "erste", last: "letzte" }}
								className="pagination-sm"
								onPageChanged={this.onPageChange.bind(this)}
							/>
						</div>}
				</div>
			</CSSTransitionGroup>
		);
	}
}

export default connect(state => ({
	stats: state.stats,
	profile: state.auth.profile
}))(Stats);
