import React from 'react';
import { connect } from 'react-redux';
import { updateShippingAddress } from '../actions/checkoutActions';

class ShippingAddressForm extends React.Component {

  handleChange(e) {
    // this.props.shippingaddress[e.target.name] = ;
    const payload = {
      fieldname: e.target.name,
      value: e.target.value,
    };
    this.props.dispatch(updateShippingAddress(payload));
  }

  render() {
    const { salutation, firstname, lastname,
       street, number, zipcode, city } = this.props.shippingaddress;
    return (
      <form>
        <p>Bitte hier die Empfängeradresse für die Tickets eingeben.</p>
        <div className="row">
          <div className="col-xs-3">
            <div className="form-group">
              <label htmlFor="salutation">Anrede</label>
              <select
                className="form-control" name="salutation"
                id="salutation" onChange={this.handleChange.bind(this)}
              >
                <option checked={(salutation === 'Herr') ? 'checked' : ''} value="Herr">Herr</option>
                <option checked={(salutation === 'Frau') ? 'checked' : ''} value="Frau">Frau</option>
              </select>
            </div>
          </div>
          <div className="col-xs-4">
            <div className="form-group">
              <label htmlFor="firstname">Vorname</label>
              <input
                type="text" className="form-control"
                id="firstname" value={firstname} name="firstname"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
          <div className="col-xs-5">
            <div className="form-group">
              <label htmlFor="lastname">Nachname</label>
              <input
                type="text" className="form-control"
                id="lastname" value={lastname} name="lastname"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
          <div className="col-xs-9">
            <div className="form-group">
              <label htmlFor="street">Straße</label>
              <input
                type="text" className="form-control"
                id="street" value={street} name="street"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
          <div className="col-xs-3">
            <div className="form-group">
              <label htmlFor="number">Nr.</label>
              <input
                type="text" className="form-control"
                id="number" value={number} name="number"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <div className="form-group">
              <label htmlFor="zipcode">PLZ</label>
              <input
                type="text" className="form-control"
                id="zipcode" value={zipcode} name="zipcode"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
          <div className="col-xs-8">
            <div className="form-group">
              <label htmlFor="city">Ort</label>
              <input
                type="text" className="form-control"
                id="city" value={city} name="city"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default connect()(ShippingAddressForm);
