/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React from 'react';
import Currency from '../tools/Currency';

class FormField extends React.Component {

  onSelectedChange() {
    this.props.changeSelected(this.props.data.id);
  }

  render() {
    const { name, price } = this.props.data;
    // const ischecked = (id === this.props.currentvalue)
    //         ? 'checked="checked"'
    //         : '';
    const {remainingtickets} = this.props;
    const isdisabled = (remainingtickets === undefined ||remainingtickets === null|| remainingtickets > 0) ? "" : "disabled";
    const remainingtext = remainingtickets !== undefined && remainingtickets !== null
        ? remainingtickets
        : 'unlimitiert';

    // const price = (this.props.price + pricechange);
    return (
      <tr>
        <td><button disabled={isdisabled}
          onClick={this.onSelectedChange.bind(this)}
          className="btn btn-block btn-xs btn-default"
        >{name}</button></td>
        <td className="text-right">{remainingtext === 0 ? "keine" : remainingtext}</td>
        <td className="text-right"><Currency value={price} /></td>
      </tr>
    );
  }
}

export default FormField;
