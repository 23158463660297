import { server } from "../app/serverapi";

// export function setInterval(interval) {
//   return {
//     type: 'SET_STATS_INTERVAL',
//     payload: interval,
//   };
// }

export function requestSendPerMail() {
	return (dispatch, getState) => {
		const state = getState();
		const profile = state.auth.profile;
		const {config} = state.stats;
		const sendData = {
			partnerid: profile.partnerid,
			pointid: profile.pointid,
			userid: profile.userid,
			start: config.start.format("YYYY-MM-DD"),
			end: config.end.format("YYYY-MM-DD"),

		};	
		dispatch({
			type: "POST_SEND_PER_MAIL_REQUEST",
			payload: server.post("export/orders", sendData)
		})
	};
}

function fireChangeDateRange(dateRange) {
	return {
		type: "STATS_CHANGE_DATE_RANGE",
		payload: dateRange
	};
}

function fireChangePage(page) {
	return {
		type: "STATS_CHANGE_PAGE",
		payload: page
	};
}

export function changePage(page) {
	return (dispatch, getState) => {
		dispatch(fireChangePage(page));
		dispatch(changeStatsConfig(getState()));
	};
}

export function changeDateRange(dateRange) {
	return (dispatch, getState) => {
		dispatch(fireChangeDateRange(dateRange));
		dispatch(changeStatsConfig(getState()));
	};
}

export function changeInterval(interval) {
	return (dispatch, getState) => {
		dispatch({
			type: "STATS_CHANGE_INTERVAL",
			payload: interval
		});

		dispatch(changeStatsConfig(getState()));
	};
}

export function changeType(type) {
	return (dispatch, getState) => {
		dispatch({
			type: "STATS_CHANGE_TYPE",
			payload: type
		});
		dispatch(changeStatsConfig(getState()));
	};
}

export function loadStats() {
	return (dispatch, getState) => {
		dispatch(changeStatsConfig(getState()));
	};
}

function changeStatsConfig(state) {
	const profile = state.auth.profile;
	const {config, paginationtotals, paginationorders} = state.stats;
	const pagination = config.type === "orders" ? paginationorders : paginationtotals;

	const sendData = {
		start: config.start.format("YYYY-MM-DD"),
		end: config.end.format("YYYY-MM-DD"),
		interval: config.interval,
		type: config.type,

		role: profile.role,
		partnerid: profile.partnerid,
		pointid: profile.pointid,
		username: profile.username,
		page: pagination.page
	};

	switch (sendData.type) {
		case "orders": {
			switch (sendData.role) {
				case "partneradmin": {
					return getPartnerOrdersSellings(sendData);
				}
				case "pointadmin": {
					return getPointOrdersSellings(sendData);
				}
				case "seller": {
					return getUserOrdersSellings(sendData);
				}
			}
		}
		case "totals": {
			switch (sendData.role) {
				case "partneradmin": {
					return getPartnerTotalSellings(sendData);
				}
				case "pointadmin": {
					return getPointTotalSellings(sendData);
				}
				case "seller": {
					return getUserTotalSellings(sendData);
				}
			}
		}
	}
}

export function getPartnerOrdersSellings({
	partnerid,
	start,
	end,
	page
}) {
	return {
		type: "GET_PARTNER_ORDERS_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/orders?start=" +
			start +
			"&end=" +
			end +
			"&page=" +
			page
		)
	};
}

export function getPointOrdersSellings({
	partnerid,
	pointid,
	start,
	end,
	page
}) {
	return {
		type: "GET_POINTS_ORDERS_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/points/" +
			pointid +
			"/orders?start=" +
			start +
			"&end=" +
			end +
			"&page=" +
			page
		)
	};
}

export function getUserOrdersSellings({
	partnerid,
	pointid,
	username,
	start,
	end,
	page
}) {
	return {
		type: "GET_USER_ORDERS_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/points/" +
			pointid +
			"/users/" +
			username +
			"/orders?start=" +
			start +
			"&end=" +
			end +
			"&page=" +
			page
		)
	};
}

export function getPartnerTotalSellings({
	partnerid,
	start,
	end,
	interval,
	page
}) {
	return {
		type: "GET_PARTNER_TOTAL_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/totals?start=" +
			start +
			"&end=" +
			end +
			"&interval=" +
			interval +
			"&page=" +
			page
		)
	};
}

export function getPointTotalSellings({
	partnerid,
	pointid,
	start,
	end,
	interval,
	page
}) {
	return {
		type: "GET_POINT_TOTAL_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/points/" +
			pointid +
			"/totals?start=" +
			start +
			"&end=" +
			end +
			"&interval=" +
			interval +
			"&page=" +
			page
		)
	};
}

export function getUserTotalSellings({
	partnerid,
	pointid,
	username,
	start,
	end,
	interval,
	page
}) {
	return {
		type: "GET_USER_TOTAL_SELLINGS",
		payload: server.get(
			"statistics/partners/" +
			partnerid +
			"/points/" +
			pointid +
			"/users/" +
			username +
			"/totals?start=" +
			start +
			"&end=" +
			end +
			"&interval=" +
			interval +
			"&page=" +
			page
		)
	};
}

function toQueryString(paramsObject) {
	return Object.keys(paramsObject)
		.map(
		key =>
			`${encodeURIComponent(key)}=${encodeURIComponent(
				paramsObject[key]
			)}`
		)
		.join("&");
}
