import React from 'react';

class TicketSummaryFormSeparator extends React.Component {
  render() {
    const { formfield } = this.props;
    // console.log(formfield);
    // return null;
    return (
      <tr key={formfield.id}>
        <th colSpan="2"><h3>Ticket Nr. {this.props.currentticketnumber}</h3></th>
      </tr>
    );
  }
}

export default TicketSummaryFormSeparator;
