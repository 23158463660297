import * as R from 'ramda';

const initialState = {
  pointlist: [],
  editpoint: {},
  edituser: {},
  pointdetails: {
    city: "",
    name: "",
    number: "",
    pointid: 0,
    status: 0,
    street: "",
    users: [],
    zipcode: "",
  },
  emptypoint: {
    city: "",
    name: "",
    number: "",
    status: 1,
    street: "",
    zipcode: "",
    pointid: false,
  },
  emptyuser: {
    email: "",
    firstname: "",
    lastname: "",
    role: "seller",
    salutation: "Herr",
    status: 1,
    userid: false,
    username: "",
    password: "",
    password2: "",
    pwsetactive: true
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_POINTS_FULFILLED':
      {
        const newstate = state;
        newstate.pointlist = action.payload.data
        return newstate;
      }
    case 'START_EDITING_POINT':
      {
        const newstate = state;
        newstate.editpoint = R.find(R.propEq('pointid', action.payload))(state.pointlist);
        return newstate;
      }

    case 'CANCEL_EDITING_POINT':
      {
        return { ...state,
          editpoint: {}
        }
      }

    case 'START_ADDING_POINT':
      {
        return { ...state,
          editpoint: state.emptypoint
        };
      }


    case 'SAVE_EDITING_POINT_FULFILLED':
      {
        return { ...state,
          editpoint: {}
        }
      }
    case 'SAVE_EDITING_POINT_REJECTED':
      {
        return { ...state,
          editpoint: {}
        }
      }
    case 'UPDATE_EDITING_POINT':
      {
        const editpoint = { ...state.editpoint
        };
        if (action.payload.name !== "active") {
          editpoint[action.payload.name] = action.payload.value;
        } else {
          editpoint["status"] = editpoint["status"] === 1 ? 0 : 1;
        }
        return { ...state,
          editpoint
        }
      }


    case 'LOAD_POINT_DETAILS_FULFILLED':
      {
        const pointdetails = action.payload.data;
        return { ...state,
          pointdetails
        };
      }





    case 'START_EDITING_USER':
      {
        const edituser = action.payload;
        return { ...state,
          edituser
        };
      }

    case 'START_ADDING_USER':
      {
        return { ...state,
          edituser: state.emptyuser,
        };
      }

    case 'SAVE_EDITING_USER_FULFILLED':
    case 'CANCEL_EDITING_USER':
      {
        return { ...state,
          edituser: {}
        }
      }


    case 'SAVE_EDITING_USER_PASSWORDS_NOT_MATCHING':
      {
        const edituser = { ...state.edituser
        };
        edituser["passwordstate"] = "has-error";
        edituser["notice"] = "Die eingegebenen Passwörter stimmen nicht überein.";
        return { ...state,
          edituser
        }
      }

    case 'SAVE_EDITING_USER_PASSWORDS_EMPTY':
      {
        const edituser = { ...state.edituser
        };
        edituser["passwordstate"] = "has-error";
        edituser["notice"] = "Das Passwort-Feld darf nicht leer sein.";
        return { ...state,
          edituser
        }
      }

    case 'UPDATE_EDITING_USER':
      {
        const edituser = { ...state.edituser
        };
        edituser[action.payload.name] = action.payload.value;

        if (edituser.password !== "" || edituser.password2 !== "") {
          if (edituser.password !== edituser.password2) {
            edituser["passwordstate"] = "has-warning";
          } else {
            edituser["passwordstate"] = "has-success";
          }
        } else {
          edituser["passwordstate"] = "";
        }
        return { ...state,
          edituser
        }
      }

    default:
      {
        return state;
      }
  }
};

export default reducer;
