import { LOCATION_CHANGE } from "react-router";
import moment from "moment";

const end = moment();
const duration = moment.duration(14, 'd');
const start = moment().subtract(duration);

const initialState = {
    config: {
        interval: "weekly",
        type: "totals",
        page: 0,
        start,
        end,
    },
    paginationtotals: {
        totalitems: 0,
        pagesize: 20,
        totalpages: 1,
        page: 0,
        lastpage: true
    },
    paginationorders: {
        totalitems: 0,
        pagesize: 20,
        totalpages: 1,
        page: 0,
        lastpage: true
    },
    totals: [],
    orders: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_STATS_INTERVAL": {
            const config = { ...state.config, interval: action.payload };
            return { ...state, config };
        }

        case "STATS_CHANGE_DATE_RANGE": {
            const config = {
                ...state.config,
                start: action.payload.startDate,
                end: action.payload.endDate
            };

            // bei einer Datums-Änderung die Seitenzahl zurücksetzen
            const paginationtotals = { ...state.paginationtotals, page: 0 };
            const paginationorders = { ...state.paginationorders, page: 0 };

            return { ...state, config, paginationorders, paginationtotals };

        }

        case "STATS_CHANGE_PAGE": {

            const paginationtotals = { ...state.paginationtotals };
            const paginationorders = { ...state.paginationorders };
            
            // Aufgrund des mitgesendeten Typs entscheiden,
            // welche Pagination angepasst wird. Im Gegesatz zu
            // state.config.* existiert pro Typ eine eigene Pagination
            if (state.config.type === "orders") {
                paginationorders.page = action.payload;
            } else {
                paginationtotals.page = action.payload;
            }
            return { ...state, paginationorders, paginationtotals };
        }

        case "STATS_CHANGE_INTERVAL": {
            const config = {
                ...state.config,
                interval: action.payload
            };

            // bei einer Interval-Änderung die Seitenzahl wieder auf 1 setzen
            const paginationtotals = { ...state.paginationtotals, page: 0 };

            return { ...state, config, paginationtotals };
        }

        case "STATS_CHANGE_TYPE": {
            const config = {
                ...state.config,
                type: action.payload
            };

            return { ...state, config };
        }

        case "GET_PARTNER_TOTAL_SELLINGS_FULFILLED":
        case "GET_POINT_TOTAL_SELLINGS_FULFILLED":
        case "GET_USER_TOTAL_SELLINGS_FULFILLED": {
            const { totalitems, config, pagination } = action.payload.data;

            // Start und Ende werden von den Komponenten als 
            // momentjs-Objekte erwartet.
            config.start = moment(config.start);
            config.end = moment(config.end);

            // Workaround: die Page kommt manchmal nicht als Integer
            pagination.page = parseInt(pagination.page, 10);
            // pagination.page = state.paginationtotals.page;
            return { ...state, totals: totalitems, config, paginationtotals: pagination };
        }

        case "GET_PARTNER_ORDERS_SELLINGS_FULFILLED":
        case "GET_POINTS_ORDERS_SELLINGS_FULFILLED":
        case "GET_USER_ORDERS_SELLINGS_FULFILLED": {
            const { orders, config, pagination } = action.payload.data;
            
            // Start und Ende werden von den Komponenten als 
            // momentjs-Objekte erwartet.
            config.start = moment(config.start);
            config.end = moment(config.end);

            // der Interval wird bei den Orders nicht benutzt, aber 
            // er wird einfach so mit durchgeschleift. 
            config.interval = state.config.interval;

            // Workaround: die Page kommt manchmal nicht als Integer
            pagination.page = parseInt(pagination.page, 10);
            return { ...state, orders, config, paginationorders: pagination };
        }

        default: {
            return state;
        }
    }
}
