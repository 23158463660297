import React from 'react';
import { Button } from "react-bootstrap";

class BtnGroupButton extends React.Component {

  onChange() {
    this.props.onChange(this.props.data.name);
  }

  render() {
    return (
        <Button bsStyle={this.props.active ? "info" : "default"} onClick={this.onChange.bind(this)}>{this.props.data.title}</Button>
    );
  }
}

export default BtnGroupButton;
