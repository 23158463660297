import React from 'react';
import { connect } from 'react-redux';
import { FormControl, FormGroup, InputGroup, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import {clearSearch, updateSearch } from "../actions/eventActions";

class EventListSearch extends React.Component {

  clearSearch() {
    this.props.dispatch(clearSearch());
    this.props.onQueryChanged("");
  }

  updateSearch(e) {
    this.props.onQueryChanged(e.target.value);
    this.props.dispatch(updateSearch(e.target.value));
  }

  render() {
    return (

      <FormGroup className="eventsearch">
        <InputGroup>
          <FormControl
          id="formControlsText"
          type="text"
          value={this.props.eventlist.query}
          placeholder="Filter nach Name, Veranstalter, ..."
          onChange={this.updateSearch.bind(this)}
          />
          <InputGroup.Button>
          <Button onClick={this.clearSearch.bind(this)}><FontAwesome name="close" /></Button>
          </InputGroup.Button>
        </InputGroup>
      </FormGroup>


      )
  }
}

export default connect(
  state => ({
    eventlist: state.eventlist,
  }),
)(EventListSearch);
