import React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
// import Popup from 'react-popup';
import { loadReservations, unreserve, unreserveAll, buyAllTickets, openTicket } from '../actions/shopActions';
import { updateEMailAddress, showCheckoutPopup, toggleHidePriceOnTicket } from '../actions/checkoutActions';
import ReservationItem from './ReservationItem';
import Currency from '../tools/Currency';
import ShippingSwitch from './ShippingSwitch';

// import ShippingAddressForm from './ShippingAddressForm';

class Reservations extends React.Component {
  // onBuy(reservationid) {
  //   this.props.dispatch(buyTicket(reservationid));
  // }
  //
  state = {
    disabled: false,
  };


  componentDidMount() {
    const intervalid = setInterval(() => {
        this.props.dispatch(loadReservations());
    }, 30000);
    this.setState({intervalid});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }


  onClickOnLoad() {
    this.props.dispatch(loadReservations());
  }

  onToggleHidePriceOnTicketClick() {
    this.props.dispatch(toggleHidePriceOnTicket());
  }

  handleUpdateEMailAddress(e) {
    this.props.dispatch(updateEMailAddress(e.target.value));
  }

  submit() {
    this.setState({
      disabled: true,
    })
    this.props.dispatch(buyAllTickets());
  }

  showCheckoutPopup() {
    this.props.dispatch(showCheckoutPopup());
  }

  checkoutPopup() {
    this.props.dispatch(buyAllTickets());
    // Popup.prompt('Nach Adresse fragen', 'What\'s your name?', {
    //   placeholder: 'Placeholder yo',
    //   type: 'text',
    // }, {
    //   text: 'Tickets jetzt erzeugen',
    //   className: 'success',
    //   action: function (Box) {
    //     this.props.dispatch(unreserve(reservationid));
    //     Box.close();
    //   }.bind(this),
    // });
  }

  removeReservation(reservationid) {
    this.props.dispatch(unreserve(reservationid));
    // Popup.prompt('Das Ticket wirklich löschen?', 'What\'s your name?', {
    //   placeholder: 'Placeholder yo',
    //   type: 'text',
    // }, {
    //   text: 'ja, löschen!',
    //   className: 'success',
    //   action: function (Box) {
    //     this.props.dispatch(unreserve(reservationid));
    //     Box.close();
    //   }.bind(this),
    // });
  }

  clearReservations() {
    this.props.dispatch(unreserveAll());
  }

  render() {
    const { disabled } = this.state;
    const items = this.props.reservations.items.map(item =>
      <ReservationItem
        removeReservation={this.removeReservation.bind(this)}
        eventslist={this.props.eventlist}
        key={item.reservationid}
        reservation={item}
        showDetails={this.props.shop.detailsvisible[item.reservationid]}
      />);

    const fees = this.props.reservations.stats.fees.map(fee => <tr key={fee.name}>
      <th>{ fee.name }</th>
      <td className="text-right">
        <Currency value={fee.value} />
      </td>
      <td>&nbsp;</td>
    </tr>);

    const submitbutton = (this.props.reservationsettings.shipping === true) ? (<Button
      onClick={this.showCheckoutPopup.bind(this)}
      className="btn btn-block btn-success"
    ><FontAwesome name="envelope-o" /> Lieferadresse eingeben</Button>)
        : (<Button
          onClick={this.submit.bind(this)}
          className="btn btn-block btn-success"
          disabled={disabled}
        ><FontAwesome name="print" /> Buchung abschließen</Button>);

    const emailplaceholder = this.props.viewconfig.emailoptional === true ? "E-Mail-Adresse (optional)" : "E-Mail-Adresse";

    if (this.props.reservations.items.length > 0) {
      return (
        <div id="reservations">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h3 className="panel-title">Warenkorb &nbsp;
                <div className="pull-right">
                  <button
                    onClick={this.clearReservations.bind(this)}
                    className="btn btn-xs btn-danger"
                  >
                    <FontAwesome name="remove" />&nbsp; alle löschen</button>
                </div>
              </h3>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Tickets</th>
                  <th style={{ width: '90px' }} className="text-right">Preis</th>
                  <th style={{ width: '40px' }}>&nbsp;</th>
                </tr>
              </thead>
              <tfoot>
                <tr className="invoice-total">
                  <th>Gesamtpreis</th>
                  <td className="text-right">
                    <Currency value={this.props.reservations.stats.totalprice} />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                {fees}
              </tfoot>
              <tbody>{items}</tbody>

            </table>
          </div>


          {this.props.viewconfig.hardcoveroption !== "remove" && <ShippingSwitch />}

          {this.props.viewconfig.giftoption !== "remove" && <div className="row">
            <div className="col-xs-9"><label style={{ display: 'block' }} htmlFor="hidePriceOnTicket">Geschenkoption: Kein Preis auf Ticket</label></div>
            <div className="col-xs-3 text-right">
              <Toggle
                id="hidePriceOnTicket"
                checked={this.props.reservationsettings.hidePriceOnTicket}
                onChange={this.onToggleHidePriceOnTicketClick.bind(this)}
              />
            </div>
          </div>}


          <div className="form-group">
            <label style={{ fontWeight: 'normal' }} htmlFor="email">{this.props.viewconfig.emailhint} </label>
            <input type="text" placeholder={emailplaceholder} className="form-control" value={this.props.reservationsettings.email} id="email" name="email" onChange={this.handleUpdateEMailAddress.bind(this)} />
          </div>


          { submitbutton }
        </div>
      );
    }
    return (
      <div className="alert alert-info" role="alert">Der Warenkorb ist leer.</div>
    );
  }
}

export default connect(
  state => ({
    reservations: state.reservations,
    shop: state.shop,
    reservationsettings: state.reservationsettings,
    eventlist: state.eventlist,
    viewconfig: state.auth.view,
  }),
)(Reservations);
