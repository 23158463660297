const initialState = {
  shipping: false,
  email: '',
  buyresult: {
    message: '',
    success: null
  },
  hidePriceOnTicket: false,
  shippingaddress: {
    salutation: 'Herr',
    firstname: '',
    lastname: '',
    street: '',
    number: '',
    zipcode: '',
    city: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_RESERVATIONS_FULFILLED':
      {
        return {
          ...state,
          shipping: action.payload.data.settings.shipping,
        };
      }
    case 'LOAD_RESERVATION_SETTINGS_FULFILLED':
      {
        return Object.assign(state, action.payload.data);
      }
    case 'SET_SHIPPING':
      {
        return {
          ...action.payload.data,
          ship: action.payload,
        };
      }
    case 'UPDATE_SHIPPING_ADDRESS':
      {
        const shippingaddress = {
          ...state.shippingaddress,
        };
        shippingaddress[action.payload.fieldname] = action.payload.value;
        return {
          ...state,
          shippingaddress,
        };
      }

    case 'UPDATE_EMAIL_ADDRESS':
      {
        return {
          ...state,
          email: action.payload,
        };
      }

    case 'TOGGLE_HIDE_PRICE_ON_TICKET':
      {
        const hidePriceOnTicket = !state.hidePriceOnTicket;
        return {
          ...state,
          hidePriceOnTicket,
        };
      }
      case 'BUY_TICKET_REJECTED':
        {
          const buyresult = action.payload.data;
          return {
            ...state,
            buyresult
          };
        }

      case 'BUY_PRESELECTED_TICKET_FULFILLED':
      case 'BUY_TICKET_FULFILLED':
        {
          return {
            ...initialState
          };
        }

    default:
      {
        return state;
      }
  }
}
