import * as R from 'ramda';
import {
  toastr
} from 'react-redux-toastr'

import {
  server
} from '../app/serverapi';


export function changeFee(type, value) {
  return {
    type: 'CHANGE_FEE',
    payload: {
      type,
      value
    },
  };
}

export function loadInfo() {
  return {
    type: 'LOAD_PARTNER_INFO',
    payload: server.get('partnerinfo'),
  };
}
export function updatePasswordField(payload) {
  return {
    type: 'UPDATE_PASSWORD_FIELD',
    payload: payload
  };
}

export function setPassword(payload) {
  if (payload.newpassword !== payload.newpassword2) {
    return {
      type: 'PASSWORD_FIELD_PASSWORDS_NOT_MATCHING',
      payload: payload
    };
  }

  const sendfields = R.pick(['oldpassword', 'newpassword'], payload);
  return (dispatch) => {
    server.post("password", sendfields).then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        if (response.data.message) {
          toastr.success('Erfolg', response.data.message);
        }
        dispatch({
          type: 'SET_PASSWORD_FULFILLED',
          payload: response.data
        });
      } else {
        if (response.data.message) {
          toastr.warning('Hinweis', response.data.message);
        }
        dispatch({
          type: 'SET_PASSWORD_REJECTED',
          payload: response.data
        });
      }
    });
  };


}

export function submitFees() {
  return {
    type: 'SUBMIT_FEES',
  };

  return {
    type: '',
  };
}
