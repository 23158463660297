import React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import {loadPointDetails, addUser} from "../../actions/pointsActions";
import Modal from "./Modal";
import ListRow from "./ListRow";
import { Button } from 'react-bootstrap';
import * as R from 'ramda';
class List extends React.Component {

  componentDidMount() {
    this.props.dispatch(loadPointDetails(this.props.pointid));
    // this.props.dispatch(loadPointDetails(this.props.pointid));
  }

  startCreating() {
    this.props.dispatch(addUser(this.props.pointdetails.pointid));
  }


  render() {
    // console.log(this.props.pointid);
    const activeuserlist = this.props.pointdetails.users.filter((user) => user.status === 1).map((user) => <ListRow key={"user" + user.userid} currentrole={this.props.role} user={user} pointid={this.props.pointdetails.pointid} />, this);
    const inactiveuserlist = this.props.pointdetails.users.filter((user) => user.status !== 1).map((user) => <ListRow key={"user" + user.userid} currentrole={this.props.role} user={user} pointid={this.props.pointdetails.pointid} />, this);
    const edituser = this.props.edituser;

    const isPointDetailsList = this.props.pointdetails.pointid !== "mine";
    const headline = this.props.pointdetails.name;

    return (
      <div>
        <h3>VVK-Stelle "{headline}"</h3>
        <Button className="btn btn-info" onClick={this.startCreating.bind(this)} ><FontAwesome name="plus-circle" /> Nutzer hinzufügen</Button>&nbsp;
        { isPointDetailsList && <a href="#/verwaltung/vvklist" className="btn pull-right btn-warning"><FontAwesome name="arrow-up" /> zurück zu den VVK-Stellen</a>}
        <h4>aktive Verkäufer</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nachname</th>
              <th>Vorname</th>
              <th>Nutzername</th>
              <th>Rolle</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {activeuserlist}
          </tbody>
        </table>

        <h4>deaktivierte Verkäufer</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nachname</th>
              <th>Vorname</th>
              <th>Nutzername</th>
              <th>Rolle</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {inactiveuserlist}
          </tbody>
        </table>
        <Modal user={edituser} pointid={this.props.pointdetails.pointid}  role={this.props.role}/>
      </div>
    );
  }
}

export default connect(state => ({
  pointdetails: state.points.pointdetails,
  role: state.auth.profile.role,
  edituser: state.points.edituser
}))(List);
