const initialState = {
  orderlist: [],
  showdetails: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CANCELLATIONS_LOAD_FULFILLED':
      {
        return { ...state,
          orderlist: action.payload.data
        };
      }

    case 'CANCELLATIONS_LOAD_REJECTED':
      {
        return state;
      }
    case 'CANCELLATIONS_CANCEL_ORDER_FULFILLED':
    case 'CANCELLATIONS_CANCEL_TICKET_FULFILLED':
      {
        if (action.payload.success === true) {
          return { ...state,
            orderlist: action.payload.orders
          }
        }
        return state;
      }
    case 'CANCELLATIONS_CANCEL_ORDER_REJECTED':
    case 'CANCELLATIONS_CANCEL_TICKET_REJECTED':
      {
        return state;
      }
    case 'CANCELLATIONS_SWITCH_DETAILS':
      {
        const newstate = {...state};
        newstate.showdetails = newstate.showdetails === action.payload ? false : action.payload;
        return newstate;
      }

    default:
      {
        return state;
      }
  }
};

export default reducer;
