/* eslint react/no-danger: 0 */

import React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { ActionCreators } from 'redux-undo';
import TicketSummary from './TicketSummary/';
import UndoButton from './UndoButton';

class Checkout extends React.Component {

  undo() {
    this.props.dispatch(ActionCreators.undo());
  }

  render() {

    const undoAvailable = ((this.props.tickethistory.past.length === 1 && this.props.tickethistory.past[0].globalid !== false) || this.props.tickethistory.past.length > 1);
    const event = this.props.ticket.event;
    const eventinfo = {
      __html: event.info,
    };
    return (
      <div>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">{event.name}</h3>
          </div>
          <div className="panel-body">
            <TicketSummary ticket={this.props.ticket} />
            <div className="row">
              <div className="col-xs-4">
                <UndoButton undoAvailable={undoAvailable} />
              </div>
              <div className="col-xs-8">
                <button
                  className="btn btn-warning btn-block"
                  onClick={this.props.onReserve}
                ><FontAwesome name="cart-arrow-down" />
                &nbsp;in den Warenkorb</button>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            {event.place && <div><FontAwesome fixedWidth name="clock-o" /> <strong>Ort:</strong> {event.place}</div>}
            {event.date && <div><FontAwesome fixedWidth name="map-marker" /> <strong>Datum:</strong>  {event.date}</div>}
            {event.host && <div><FontAwesome fixedWidth name="star" /> <strong>Veranstalter:</strong> {event.host}</div>}
          </div>
        </div>

        <div dangerouslySetInnerHTML={eventinfo} />
      </div>
    );
  }
}

export default connect(state => ({
  tickethistory: state.ticket,
}))(Checkout);
