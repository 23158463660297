import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Shop from './Shop';
import Cancellation from './Cancellation';
import Stats from './Stats/';
import Admin from './Admin';

class PageContent extends React.Component {
  render() {

    const CancellableComponent = (props) => {
      return (
        <Cancellation showCancelButton={true} />
      );
    };

    const NotCancellableComponent = (props) => {
      return (
        <Cancellation showCancelButton={false} />
      );
    };



    return (
      <div className="pageContent">
        <Switch>
          <Route path="/shop" component={Shop} />
          <Route path="/storno" component={CancellableComponent} />
          <Route path="/verlauf" component={NotCancellableComponent} />
          <Route path="/statistiken" component={Stats} />
          <Route path="/verwaltung/:tab/:pointid?" component={Admin} />
          <Redirect to={this.props.defaultRoute} />
        </Switch>
      </div>
    );
  }

}

export default PageContent;
