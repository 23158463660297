const initialState = {
  buyable: false,
  globalid: false,
  price: 0,
  loaded: false,
  nextconstraint: {},
  selectedconstraints: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'RESET_TICKET':
      {
        return {
          ...initialState,
        };
      }
    case 'LOAD_TICKET_FULFILLED':
    case 'UPDATE_TICKET_FULFILLED':
      {
        return {
          ...state,
          loaded: true,
          buyable: action.payload.data.buyable,
          event: action.payload.data.event,
          price: action.payload.data.price,
          message: action.payload.data.message,
          globalid: action.payload.data.globalid,
          nextconstraint: action.payload.data.nextconstraint,
          selectedconstraints: action.payload.data.selectedconstraints,
        };
      }

    case 'CHANGE_FORM_FIELD_AMOUNT':
      {
        const formfields = state.nextconstraint.formfields;
        const currentfieldindex =
          formfields.findIndex(formfield => formfield.id === action.payload.id);
        const newstate = {
          ...state,
        };
        newstate.nextconstraint.formfields[currentfieldindex].value =
        parseInt(action.payload.value, 10);
        return newstate;
      }
    case 'INCREASE_FORM_FIELD_AMOUNT':
      {
        const formfields = state.nextconstraint.formfields;
        const currentfieldindex = formfields.findIndex(formfield =>
          formfield.id === action.payload.id);
        const newstate = {
          ...state,
        };
        newstate.nextconstraint.formfields[currentfieldindex].value += 1;
        return newstate;
      }
    case 'DECREASE_FORM_FIELD_AMOUNT':
      {
        const formfields = state.nextconstraint.formfields;
        const currentfieldindex = formfields.findIndex(formfield =>
          formfield.id === action.payload.id);
        const newstate = {
          ...state,
        };
        if (newstate.nextconstraint.formfields[currentfieldindex].value > 0) {
          newstate.nextconstraint.formfields[currentfieldindex].value -= 1;
        }
        return newstate;
      }

    case 'CHANGE_FORM_FIELD_VALUE':
      {
        const formfields = state.nextconstraint.formfields;
        const currentfieldindex = formfields.findIndex(formfield =>
          formfield.id === action.payload.id);
        const newstate = {
          ...state,
        };
        newstate.nextconstraint.formfields[currentfieldindex].value =
        action.payload.value;
        return newstate;
      }

    case 'SWITCH_FORM_FIELD_VALUE':
      {
        const formfields = state.nextconstraint.formfields;
        const currentfieldindex = formfields.findIndex(formfield =>
          formfield.id === action.payload.id);
        const newstate = {
          ...state,
        };

        const val = parseInt(action.payload.value, 10);
        const selected = newstate.nextconstraint.formfields[currentfieldindex].value;
        const index = selected.indexOf(val);

        if (index === -1) {
          newstate.nextconstraint.formfields[currentfieldindex].value.push(val);
        } else {
          newstate.nextconstraint.formfields[currentfieldindex].value.splice(index, 1);
        }

        return newstate;
      }

    case 'BUY_TICKET_FULFILLED':
    case 'BUY_PRESELECTED_TICKET_FULFILLED':
      {
        return {
          ...initialState
        };
      }


    case 'CHANGE_FORM_FIELD_SELECTED':
      {
        const newstate = {
          ...state,
        };
        newstate.nextconstraint.value = action.payload.id;
        return newstate;
      }
    default:
      {
        return {
          ...state,
        };
      }
  }
}
