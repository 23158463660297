import React from 'react';

class ModalInput extends React.Component {

    handleChange(e) {
        const value = e.target.value;
        const name = this.props.data.name;
        this.props.onChange({name, value});
    }

    render() {
        const {label, value, type, size, active} = this.props.data;
        return (
            <div className={size}>
              <div className="form-group">
                <label htmlFor="vvk_firstname">{ label }</label>
                <input disabled={!active} type={type} className="form-control" value={value} onChange={this.handleChange.bind(this)}/>
              </div>
            </div>
        );
    }
}

export default ModalInput;
