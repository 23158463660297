import '../../node_modules/react-toggle/style.css';
import {PulseLoader} from 'halogenium';
import React from 'react';
import { connect } from 'react-redux';
import Delay from 'react-delay';

class Loader extends React.Component {

  render() {
    return (
        <div id="loader">
            {this.props.loader && <Delay wait={400}><PulseLoader color="#46b8da" size="64px" margin="4px"/></Delay>}
        </div>
    );
  }
}


export default connect(
  state => ({
    loader: state.loader,
  }),
)(Loader);
