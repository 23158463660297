
export function showCheckoutPopup() {
  return {
    type: 'SHOW_CHECKOUT_POPUP',
  };
}

export function hideCheckoutPopup() {
  return {
    type: 'HIDE_CHECKOUT_POPUP',
  };
}

export function updateShippingAddress(payload) {
  return {
    type: 'UPDATE_SHIPPING_ADDRESS',
    payload,
  };
}

export function updateEMailAddress(payload) {
  return {
    type: 'UPDATE_EMAIL_ADDRESS',
    payload,
  };
}

export function toggleHidePriceOnTicket() {
  return {
    type: 'TOGGLE_HIDE_PRICE_ON_TICKET',
  };
}
export function unsetPDFFile() {
  return {
    type: 'UNSET_PDF_FILE',
  };
}
