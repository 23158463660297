import { toastr } from "react-redux-toastr";
import { server } from "../app/serverapi";

export function changeFee(type, value) {
  return {
    type: "CHANGE_FEE",
    payload: { type, value }
  };
}

export function toggleReceipt() {
  return {
    type: "PARTNER_TOGGLE_RECEIPT"
  };
}

export function loadFees() {
  return {
    type: "LOAD_PARTNER_SETTINGS",
    payload: server.get("partnersettings")
  };
}

export function saveSettings(settings) {
  return dispatch => {
    server.post("partnersettings", settings).then(response => {
      if (response.data.success === true) {
        if (response.data.message) {
          toastr.success("Erfolg", response.data.message);
        }
        dispatch({
          type: "SAVE_PARTNER_SETTINGS_FULFILLED",
          payload: response
        });
      } else {
        if (response.data.message) {
          toastr.warning("Hinweis", response.data.message);
        }
        dispatch({
          type: "SAVE_PARTNER_SETTINGS_REJECTED",
          payload: response
        });
      }
    });
  };
}
