import React from "react";
import { NavLink } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { logout } from "../actions/authActions";

class Menu extends React.Component {
  componentWillMount() {
    // this.props.dispatch(setAuthKey(this.props.match.params.authkey));
  }

  logMeOut() {
    this.props.dispatch(logout());
  }

  render() {
    const links = {
      shop: '/shop',
      storno: '/storno',
      verlauf: '/verlauf',
      statistiken: '/statistiken',
      einstellungen: '/verwaltung/einstellungen',
      einstellungenpartner: '/verwaltung/einstellungenpartner',
      vvk: '/verwaltung/vvk/mine',
      vvklist: '/verwaltung/vvklist',
      partnerSettings: '/verwaltung/partnerSettings',
    };
    // console.log(this.props);

    const { custommenuitems, tabs, logo, logout } = this.props.auth.view;

    const showOnlyStatsLinks = tabs === "stats-only";

    const brand = logo !== null ? <img src={logo} /> : <span>tivents|VVK</span>;

    const dropdownTitle = (
      <span>
        <FontAwesome name="user-circle" /> {this.props.auth.profile.firstname}&nbsp;
        {this.props.auth.profile.lastname}
      </span>
    );

    const cme = custommenuitems && custommenuitems.map((custommenuitem, index) =>
      <MenuItem target="_blank" href={custommenuitem.link} key={"cme"+index}>
        <FontAwesome fixedWidth name="external-link" /> {custommenuitem.name}
      </MenuItem>
    );

    return (
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          {showOnlyStatsLinks ||
            <Navbar.Brand>
              <NavLink to={links.shop}>
                {brand}
              </NavLink>
            </Navbar.Brand>}
          <Navbar.Toggle children="🍔" />
        </Navbar.Header>
        <Navbar.Collapse>
          <ul className="nav navbar-nav">
            {showOnlyStatsLinks ||
              <li>
                <NavLink to={links.storno}>
                  <FontAwesome name="history" /> Storno
                </NavLink>
              </li>}
              {showOnlyStatsLinks ||
              <li>
                <NavLink to={links.verlauf}>
                  <FontAwesome name="ticket" /> Verlauf
                </NavLink>
              </li>}
            <li>
              <NavLink to={links.statistiken}>
                <FontAwesome name="area-chart" /> Statistiken
              </NavLink>
            </li>
          </ul>
          <Nav pullRight>
            <NavDropdown
              eventKey={3}
              title={dropdownTitle}
              id="basic-nav-dropdown"
            >
              <MenuItem eventKey="4.1" href={"#" + links.einstellungen}>
                <FontAwesome name="gears" /> persönliche Einstellungen
              </MenuItem>

              {this.props.auth.profile.role === "pointadmin" &&
                <MenuItem eventKey="4.1" href={"#" + links.vvk}>
                  <FontAwesome name="gears" /> Meine VVK-Stelle
                </MenuItem>}

              {this.props.auth.profile.role === "partneradmin" &&
                <MenuItem eventKey="4.1" href={"#" + links.vvklist}>
                  <FontAwesome name="gears" /> Meine VVK-Stellen
                </MenuItem>}

              {this.props.auth.profile.role === "partneradmin" &&
                <MenuItem eventKey="4.1" href={"#" + links.partnerSettings}>
                  <FontAwesome name="gears" /> Verkaufseinstellungen
                </MenuItem>}

              <MenuItem divider />

              {cme}
              {custommenuitems && custommenuitems.length > 0 && <MenuItem divider />}

              <MenuItem onClick={this.logMeOut.bind(this)} eventKey="4.4">
                <span className="text-warning">
                  <em>
                    <FontAwesome name="sign-out" /> {logout}
                  </em>
                </span>
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default connect(state => ({ auth: state.auth }))(Menu);
