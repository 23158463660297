import React from 'react';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import {changeFee, loadFees, saveSettings, toggleReceipt} from "../../actions/partnerSettingsActions";

class PartnerSettings extends React.Component {

  componentWillMount() {
    this.setState({accept: false});
  }

  componentDidMount() {
    this.props.dispatch(loadFees());
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.dispatch(saveSettings(this.props.partnerSettings));
  }

  onChangeFixed(e) {
    this.props.dispatch(changeFee("fixed", e.target.value));
  }

  onChangePercentage(e) {
    // const value = Math.round(e.target.value.replace(/,/, "."));
    this.props.dispatch(changeFee("percentage", e.target.value));
  }

  onChangeMinimum(e) {
    this.props.dispatch(changeFee("minimum", e.target.value));
  }

  onReceiptchange() {
    this.props.dispatch(toggleReceipt());
  }

  render() {
    const {percentage, fixed, minimum} = this.props.partnerSettings.fees;
    const receipt = this.props.partnerSettings.settings.receipt;


    return (
      <div>
        <form className="admin-form" >
          <h4>VVK-Gebühren</h4>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="fixed">Grundgebühr je Ticket:</label>
                <input type="text" className="form-control" id="fixed" value={fixed.replace(/\./,',')} name="fixed" onChange={this.onChangeFixed.bind(this)} />
                <span className="help-block">in Euro, max. zwei Nachkommastellen</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="percentage">Prozentsatz je Ticket:</label>
                <input type="text" className="form-control" id="percentage" value={percentage} name="percentage" onChange={this.onChangePercentage.bind(this)} />
                <span className="help-block">in Prozent (nur ganze Zahlen)</span>
              </div>
            </div>
          </div>

<div className="panel panel-default">
  <div className="panel-body">
       <Toggle checked={receipt} onChange={this.onReceiptchange.bind(this)} id="terms"/> <br/>
          Ich bestätige die Bedingungen für die Vorverkaufsgebühr. Insbesondere ist mir bekannt, dass bei
          allen von mir verwalteten Verkaufsstellen diese Gebühr auf den tivents-Systempreis
          aufgeschlagen und dem Kunden ausgewiesen wird; dass pro Ticket kaufmännisch auf ganze EuroCent
          gerundet wird und dass die von mir hier angegebenen Beträge sich inkl. MwSt verstehen und
          von mir entsprechend versteuert werden müssen.
  </div>
  <div className="panel-footer"><button disabled={!receipt} onClick={this.onSubmit.bind(this)} className="btn btn-success">Änderungen übernehmen</button></div>
</div>
        </form>
      </div>
    );
  }
}

/*

            <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="minimum">Minimalgebühr je Ticket:</label>
              <input type="text" className="form-control" id="minimum" name="minimum" value={minimum} onChange={this.onChangeMinimum.bind(this)}  />
              <span className="help-block">in Euro, max. zwei Nachkommastellen</span>
            </div>
            <div className="form-group">
              <label htmlFor="quittung">Quittungsdruck:</label><br/>
              <Toggle onClick={this.onReceiptchange.bind(this)} id="quittung"/> <span className="help-block">Bei jedem Verkauf eine Quittung generieren und mit den Tickets ausdrucken.</span>
            </div>
            </div>


 */

export default connect(state => ({
  partnerSettings: state.partnerSettings,
}))(PartnerSettings);
