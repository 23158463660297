const initialState = false;
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case 'SET_LOADER_STATE':
        {
          return action.payload;
        }
      default: {
        return state;
      }
    }
  }
  