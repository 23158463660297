import React from 'react';
import { connect } from 'react-redux';
import { editPoint, saveEditPoint, loadPointDetails } from '../../actions/pointsActions';

class ListRow extends React.Component {

  startEditing() {
    this.props.dispatch(editPoint(this.props.point.pointid));
  }

  listSellers() {
    
    // this.props.dispatch(listUsers(this.props.point.pointid));
  }

  toggleStatus() {
    const {point} = this.props;
    point.status = point.status === 1 ? 0 : 1;
    this.props.dispatch(saveEditPoint(point));
  }

  render() {
    const {point} = this.props;
    const linkName = point.status === 1 ? "deaktivieren" : "aktivieren";
    const adminlist = point.admins.map((admin) => <div key={admin.userid + "padmn"}>{admin.firstname} {admin.lastname} {admin.email ? "(" + admin.email + ")" : ""}</div>);
    return (
      <tr>
        <td>{point.name}</td>
        <td>{point.street} {point.number}<br />{point.zipcode} {point.city}</td>
        <td>{adminlist}</td>
        <td style={{ width: '250px' }}>
          <button onClick={this.startEditing.bind(this)} className="btn btn-xs btn-info">bearbeiten</button>&nbsp;
          <a href={"#/verwaltung/vvk/" + point.pointid} className="btn btn-xs btn-info">verwalten</a>&nbsp;
          <button onClick={this.toggleStatus.bind(this)}  className="btn btn-xs btn-warning">{linkName}</button>
        </td>
      </tr>
    );
  }
}

export default connect()(ListRow);
