const initialState = {
  fees: {
    percentage: "",
    fixed: "",
    minimum: ""
  },
  settings: {
    receipt: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_FEE':
      {
        const newstate = {...state};
        const {type, value} = action.payload;
        newstate.fees[type] = value;
        return newstate;
      }
    case 'PARTNER_TOGGLE_RECEIPT':
      {
        const newstate = {...state};
        newstate.settings.receipt = !newstate.settings.receipt;
        return newstate;
      }

    case 'LOAD_PARTNER_SETTINGS_FULFILLED':
    case 'SAVE_PARTNER_SETTINGS_FULFILLED':
      {
        const fees = action.payload.data.fees;
        const settings = {...state.settings, receipt: false};
        return {...state, fees, settings};
      }

    default:
      {
        return state;
      }
  }
};

export default reducer;
