import React from 'react';
import * as R from 'ramda';

class TicketSummaryFormDropdown extends React.Component {
  render() {
    const { formfield } = this.props;
    const selectedoption = R.find(R.propEq('id', formfield.value), formfield.options);

    return (
      <tr key={formfield.id}>
        <th>{formfield.name}</th>
        <td>{selectedoption.value}</td>
      </tr>
    );
  }
}

export default TicketSummaryFormDropdown;
