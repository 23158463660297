import React from "react";
import * as R from 'ramda';

class OrdersTableRow extends React.Component {
  render() {
    const {
      EventGlobalId,
      EventName,
      OrderId,
      OrderIncrementID,
      OrderSum,
      OrdersDate,
      PartnerId,
      PaymentFees,
      PointName,
      PriceOptions,
      Status,
      TaxClass,
      TicketCode,
      UserName,
      id
    } = this.props.row;

    const {activecols} = this.props;
    
    // Ticket-Optionen umformen
    // const PriceOptionsArray = R.toPairs(PriceOptions);
    // const po = PriceOptionsArray.map((fields, index) => <div key={"priceopt" + index}><strong>{fields[0]}</strong>: {fields[1]}</div>)
    const po = PriceOptions.map((fields, index) => <div key={"priceopt" + index}><strong>{fields.key}</strong>: {fields.value}</div>)

    return (
      <tr>
        <td>
          {OrderIncrementID}
        </td>
        <td>
          {OrdersDate}
        </td>
        <td>
          {EventName}
        </td>
        <td>
          {EventGlobalId}
        </td>
        <td>
          {po}
        </td>
        <td>
          {OrderSum}
        </td>
        <td>
          {PaymentFees}
        </td>
        <td>
          {TicketCode}
        </td>
        {activecols.pointName && <td>
          {PointName}
        </td>}
        {activecols.username && <td>
          {UserName}
        </td>}
      </tr>
    );
  }
}

export default OrdersTableRow;
