import React from "react";

function Currency(props) {
	const formatter = new Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2
	});
	return props.value > 0
		? <span>
				{formatter.format(parseFloat(props.value))}
			</span>
		: <em>kostenlos</em>;
}

/*Currency.propTypes = {
	value: React.PropTypes.number.isRequired
};
*/
export default Currency;
