import React from 'react';
import Toggle from 'react-toggle';
import FontAwesome from 'react-fontawesome';
import * as R from 'ramda';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { cancelEditPoint, saveEditPoint, updatePoint } from '../../actions/pointsActions';

class PointListModal extends React.Component {

  close() {
    this.props.dispatch(cancelEditPoint());
  }

  submit() {
    this.props.dispatch(saveEditPoint(this.props.editpoint));
  }

  handleChange(e) {
    // console.log(e.target.name, e.target.value);
    this.props.dispatch(updatePoint(e.target.name, e.target.value));
  }

  render() {
    const point = this.props.editpoint;
    if (R.isEmpty(point)) {
      return null;
    } else {

      const {city, name, number, street, zipcode, status} = point;
      const statuschecked = status === 1;
      const show = point !== {};

      return (
        <Modal show={show} onHide={this.close.bind(this)}>
        <Modal.Header closeButton>
        <Modal.Title>VVK-Stelle bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
        <div className="col-xs-12">
        <div className="form-group">
        <label htmlFor="vvk_name">Name</label>
        <input type="email" className="form-control" id="vvk_name" name="name" value={name}  onChange={this.handleChange.bind(this)}/>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col-xs-8">
        <div className="form-group">
        <label htmlFor="vvk_street">Straße</label>
        <input type="email" className="form-control" name="street" id="vvk_street" value={street}  onChange={this.handleChange.bind(this)}/>
        </div>
        </div>
        <div className="col-xs-4">
        <div className="form-group">
        <label htmlFor="vvk_number">Hausnummer</label>
        <input type="email" className="form-control" name="number" id="vvk_number" value={number}  onChange={this.handleChange.bind(this)}/>
        </div>
        </div>
        <div className="col-xs-4">
        <div className="form-group">
        <label htmlFor="vvk_postal_code">PLZ</label>
        <input type="email" className="form-control" name="zipcode" id="vvk_postal_code" value={zipcode}  onChange={this.handleChange.bind(this)}/>
        </div>
        </div>
        <div className="col-xs-8">
        <div className="form-group">
        <label htmlFor="vvk_city">Stadt</label>
        <input type="email" className="form-control" name="city" id="vvk_city" value={city}  onChange={this.handleChange.bind(this)}/>
        </div>
        </div>
        </div>
        <div className="row">
        <div className="col-xs-2 text-right"><Toggle name="active" checked={statuschecked} id="vvk_active"  onChange={this.handleChange.bind(this)} /></div>
        <div className="col-xs-10">VVK aktiv</div>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button bsStyle="warning" onClick={this.close.bind(this)}>abbrechen</Button>
        <Button onClick={this.submit.bind(this)} bsStyle="success"><FontAwesome name="print" /> Änderungen speichern</Button>
        </Modal.Footer>
        </Modal>
      );

    }
  }
}

export default connect()(PointListModal);
