import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { hideCheckoutPopup } from '../actions/checkoutActions';
import { buyAllTickets } from '../actions/shopActions';
import ShippingAddressForm from './ShippingAddressForm';

class CheckoutModal extends React.Component {

  close() {
    this.props.dispatch(hideCheckoutPopup());
  }

  submit() {
    // Historie des Ticket-Konfigurators löschen
    this.props.dispatch(ActionCreators.clearHistory());
    this.props.dispatch(buyAllTickets());
  }

  render() {
    // console.log(this.props);
    return (
      <Modal show={this.props.checkout.visible} onHide={this.close.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Lieferadresse eingeben</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.reservationsettings.shipping && <ShippingAddressForm shippingaddress={this.props.reservationsettings.shippingaddress} />}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="warning" onClick={this.close.bind(this)}>abbrechen</Button>
          <Button onClick={this.submit.bind(this)} bsStyle="success"><FontAwesome name="print" /> Buchung abschließen</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(state => ({
  checkout: state.checkout,
  reservationsettings: state.reservationsettings,
}))(CheckoutModal);
