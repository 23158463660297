import {
  toastr
} from 'react-redux-toastr'

import {
  server,
} from '../app/serverapi';

const responseHandler = (dispatch, method, actionname, postdata) => {
  server.post(method, postdata).then((response) => {
    if (response.data.success === true) {
      if (response.data.message) {
        toastr.success('Erfolg', response.data.message);
      }
      dispatch({
        type: actionname + '_FULFILLED',
        payload: response.data
      });
    } else {
      if (response.data.message) {
        toastr.success('Hinweis', response.data.message);
      }
      dispatch({
        type: actionname + '_REJECTED',
        payload: response.data
      });
    }
  });
};

export function loadCancellations() {
  return {
    type: 'CANCELLATIONS_LOAD',
    payload: server.get('orders'),
  };
}

export function cancelOrder(orderid) {
  const postdata = {
    orderid,
    object: "order"
  };
  return (dispatch) => {
    responseHandler(dispatch, "cancel", "CANCELLATIONS_CANCEL_ORDER", postdata);
  };
}

export function printOrder(orderid) {
  const postdata = {
    orderid,
    object: "order"
  };
  return (dispatch) => {
    responseHandler(dispatch, "print", "CANCELLATIONS_PRINT_ORDER", postdata);
  };
}

export function cancelTicket(ticketcode, orderid) {
  const postdata = {
    ticketcode,
    orderid,
    object: "ticket"
  };
  return (dispatch) => {
    responseHandler(dispatch, "cancel", "CANCELLATIONS_CANCEL_TICKET", postdata);
  };
}

export function printTicket(ticketcode, orderid) {
  const postdata = {
    ticketcode,
    orderid,
    object: "ticket"
  };
  return (dispatch) => {
    responseHandler(dispatch, "print", "CANCELLATIONS_PRINT_TICKET", postdata);
  };
}

export function switchDetails(orderid) {
  return {
    type: 'CANCELLATIONS_SWITCH_DETAILS',
    payload: orderid,
  };
}
