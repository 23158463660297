import { server } from '../app/serverapi';

export function reservationToggleShowDetailsState(reservationid) {
  return {
    type: 'RESERVATION_TOGGLE_SHOW_DETAILS_STATE',
    payload: reservationid,
  };
}

export function loadReservationSettings() {
  return {
    type: 'LOAD_RESERVATION_SETTINGS',
    payload: server.get('/reservationsettings'),
  };
}

export function setShipping(shipping) {
  return (dispatch) => {
    server.post('reservationsettings', { shipping }).then((response) => {
      dispatch({ type: 'LOAD_RESERVATIONS_FULFILLED', payload: response });
    });
  };
}
