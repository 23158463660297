import React from 'react';
import * as R from 'ramda';

class TicketSummaryFormMultiSelect extends React.Component {
  render() {

    // Formfield holen
    const { formfield } = this.props;

    // ausgewählte Optionen herausfiltern
    const selectedvalues = R.innerJoin((option, id) => option.id === id,formfield.options, formfield.value);

    // HTML pro List-Item
    const listitem = listitemdata => <li key={listitemdata.id}>{listitemdata.value}</li>

    // Liste aus List-Items bauen
    const list = R.isEmpty(selectedvalues) ? <em>Keine Auswahl getroffen.</em> : R.map(listitem, selectedvalues);

    return (
      <tr key={formfield.id}>
        <th>{formfield.name}</th>
        <td><ul>{list}</ul></td>
      </tr>
    );
  }
}

export default TicketSummaryFormMultiSelect;
