/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import Currency from '../tools/Currency';

class ReservationSummary extends React.Component {

  render() {
    const ticketliste = this.props.ticket.tickets.map((ticket, key) => <tr key={key}><td title={ticket.longdescription}>{ticket.shortdescription}</td><td><Currency value={ticket.price} /></td></tr>);
    const styles = { clear: 'both' };
    return (
      <table className="table table-striped" style={styles}><tbody>{ticketliste}</tbody></table>
    );
  }
}

export default ReservationSummary;
