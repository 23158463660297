const initialState = {
  oldpassword: "",
  newpassword: "",
  newpassword2: "",
  message: "",
  fieldstate: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_PASSWORD_FIELD':
      {
        const newstate = { ...state
        };
        newstate[action.payload.key] = action.payload.value;

        if (newstate.newpassword !== newstate.newpassword2) {
          newstate.fieldstate = "has-warning";
        }

        if (newstate.newpassword === newstate.newpassword2 && newstate.newpassword !== "") {
          newstate.fieldstate = "has-success";
        }

        if (newstate.newpassword === newstate.newpassword2 && newstate.newpassword === "") {
          newstate.fieldstate = "";
        }
        return newstate
      }

    case 'SET_PASSWORD_FULFILLED':
      {
        return initialState;
      }

    case 'PASSWORD_FIELD_PASSWORDS_NOT_MATCHING':
      {
        return {...state, message: "Das neue Passwort stimmt nicht mit der Wiederholung überein."};
      }

    default:
      {
        return state;
      }
  }
}
