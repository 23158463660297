import React from 'react';
import Toggle from 'react-toggle';
import FontAwesome from 'react-fontawesome';
import * as R from 'ramda';
import { Modal, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { cancelEditUser, saveEditUser, updateUser } from '../../actions/pointsActions';
import ModalInput from "./ModalInput";

class PointDetailsModal extends React.Component {

  close() {
    this.props.dispatch(cancelEditUser());
  }

  submit() {
    this.props.dispatch(saveEditUser({user: this.props.user, pointid: this.props.pointid}));
  }

  handleToggleChange(e) {
    const value = e.target.checked;
    const name = e.target.name;
    this.props.dispatch(updateUser({name, value}));
  }

  handleStatusChange(e) {
    const value = e.target.checked ? 1 : 0;
    const name = "status";
    this.props.dispatch(updateUser({name, value}));
  }

  handleDropdownChange(e) {
    const value = e.target.value;
    const name =  e.target.name;
    this.props.dispatch(updateUser({name, value}));
  }

  handleComponentChange(payload) {
    this.props.dispatch(updateUser(payload));
  }

  render() {
    const { email, firstname, lastname, role, salutation, status, userid, username, password, password2, passwordstate, pwsetactive, notice} = this.props.user;
    const statuschecked = status === 1;
    const currentuserrole = this.props.role;
    const emailfieldactive = this.props.role === "partneradmin" && role === "pointadmin";
    const user = this.props.user;

    const formrole = (currentuserrole === "partneradmin") ? role : "seller";
    const roledisabled = (currentuserrole === "partneradmin") ? "" : "disabled";

    const pwSwitchAvailable = userid !== false;
    const pwActive = pwSwitchAvailable === false || pwSwitchAvailable === true && pwsetactive === true;
    if (R.isEmpty(user)) {
      return null;
    } else {

      // const statuschecked = status === 1;
      return (
        <Modal show={true} onHide={this.close.bind(this)}>
          <Modal.Header closeButton>
          <Modal.Title>Benutzer bearbeiten</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          { notice && <Alert bsStyle="warning">{notice}</Alert>}
          <div className="row">
          <div className="col-xs-3">
              <div className="form-group">
                  <label htmlFor="role">Anrede</label><br/>
                  <select name="salutation" value={salutation} className="form-control" onChange={this.handleDropdownChange.bind(this)}>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                  </select>
              </div>
          </div>

            <ModalInput data={{size: "col-xs-4", type: "text", active: true, name: "firstname", label: "Vorname", value: firstname}} onChange={this.handleComponentChange.bind(this)} />
            <ModalInput data={{size: "col-xs-5", type: "text", active: true, name: "lastname", label: "Nachname", value: lastname}} onChange={this.handleComponentChange.bind(this)} />
            {emailfieldactive && <ModalInput data={{size: "col-xs-12", type: "text", active: emailfieldactive, name: "email", label: "E-Mail-Adresse", value: email}} onChange={this.handleComponentChange.bind(this)} />}
            <ModalInput data={{size: "col-xs-12", type: "text", active: true, name: "username", label: "Benutzername", value: username}} onChange={this.handleComponentChange.bind(this)} />

            {pwSwitchAvailable && <div className="col-xs-2" style={{height: "30px"}}>
                <Toggle name="pwsetactive" checked={pwsetactive} onChange={this.handleToggleChange.bind(this)} />
            </div>}
            {pwSwitchAvailable && <div className="col-xs-10" style={{height: "30px"}}>
                <label htmlFor="pwsetactive">Passwort setzen</label>
            </div>}

            <ModalInput data={{size: "col-xs-6 " + passwordstate, active: pwActive, type: "password", name: "password", label: "Passwort", value: password}} onChange={this.handleComponentChange.bind(this)} />
            <ModalInput data={{size: "col-xs-6 " + passwordstate, active: pwActive, type: "password", name: "password2", label: "Passwort wiederholen", value: password2}} onChange={this.handleComponentChange.bind(this)} />

            <div className="col-xs-6">
                <div className="form-group">
                    <label htmlFor="role">Rolle</label><br/>
                    <select name="role" value={formrole} className="form-control" disabled={roledisabled} onChange={this.handleDropdownChange.bind(this)}>
                      <option value="seller">Verkäufer</option>
                      <option value="pointadmin">VVK-Stellen-Admin</option>
                    </select>
                </div>
            </div>
            <div className="col-xs-6">
                <div className="form-group">
                    <label htmlFor="status">Verkäufer aktiv</label><br/>
                    <Toggle name="status" checked={statuschecked} onChange={this.handleStatusChange.bind(this)} />
                </div>
            </div>



          </div>
          </Modal.Body>
          <Modal.Footer>
          <Button bsStyle="warning" onClick={this.close.bind(this)}>abbrechen</Button>
          <Button onClick={this.submit.bind(this)} bsStyle="success"><FontAwesome name="print" /> Änderungen speichern</Button>
          </Modal.Footer>
        </Modal>
      );

    }
  }
}

export default connect()(PointDetailsModal);
