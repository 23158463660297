import React from 'react';
import FontAwesome from 'react-fontawesome';
import Currency from '../tools/Currency';
import ReservationItemDetails from './ReservationItemDetails';
import ReservationSummary from './ReservationSummary';
import TicketSummary from './TicketSummary/';
import * as R from 'ramda';

class ReservationItem extends React.Component {

  // componentDidMount() {
  //   const reservation = this.props.reservation;
  //   if (!this.props.events[reservation.globalid]) {
  //     this.props.loadEvent(reservation.globalid);
  //   }
  // }

  delReservation() {
    this.props.removeReservation(this.props.reservation.reservationid);
  }

  render() {
    const reservation = this.props.reservation;
    const isopen = this.props.showDetails;
    const event = reservation.event

    return (
      <tr>
        <td colSpan="3">
          <div className="pull-left" style={{ cursor: 'pointer' }}>
            {(event === undefined)
              ? <div>Reservierung #{reservation.globalid}</div>
              : <ReservationItemDetails
                reservation={reservation}
                isopen={isopen}
                event={event}
              />}
          </div>
          <button
            className="btn btn-xs btn-danger pull-right"
            title="Reservierung aufheben"
            onClick={this.delReservation.bind(this)}
          ><FontAwesome name="close" />
          </button>
          <div className="pull-right" style={{ marginRight: '5px' }}><Currency value={reservation.price} /></div>

          {(isopen) ? <TicketSummary hideFullPrice ticket={reservation} /> : ''}
          {(isopen) ? <ReservationSummary ticket={reservation} /> : ''}

        </td>
      </tr>
    );
  }
}


export default ReservationItem;
