const initialState = {
  detailsvisible: [],

};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'RESERVATION_TOGGLE_SHOW_DETAILS_STATE':
      {
        const newstate = { ...state };
        if (newstate.detailsvisible[`${action.payload}`]) {
          newstate.detailsvisible[`${action.payload}`] = false;
        } else {
          newstate.detailsvisible[`${action.payload}`] = true;
        }
        return newstate;
      }
    default: {
      return state;
    }
  }
}
