const initialState = {
  currentuser: false,
  profile: {
    firstname: '',
    lastname: '',
    salutation: '',
  },
  role: {
    id: false,
    name: false,
    // onlyshopview: false,
    // eventview: "show"
  },
  view: {
    tabs: "show",
    event: "show",
    logout: "show"
  },
  booted: false,
  errormessage: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CURRENT_USER_FULFILLED':
      {
        // action.payload.data.view.event = "show";
        // action.payload.data.view.tabs = "remove";
        const view = action.payload.data.view;
       
        const result = {
          ...state,
          currentuser: action.payload.data.username,
          profile: action.payload.data.profile,
          role: action.payload.data.role,
          view: view,
          
          errormessage: false,
          booted: true,
        };


        // action.payload.data.view.event = "show";
        // action.payload.data.view.tabs = "show";

        return result;
      }

    case 'GET_CURRENT_USER_REJECTED':
      {
        // console.log(action);
        // const errormessages = {
        //   "Unauthorized" : "",
        //   "Forbidden" : "Die Anmeldung ist fehlgeschlagen. Bitte loggen Sie sich erneut ein.",
        // }
        const servermessage = action.payload.response.data.message;

        return {
          ...state,
          errormessage: servermessage,
          booted: true,
        };
      }
    case 'UNSET_CURRENT_USER_FULFILLED':
      {
        return {
          ...state,
          currentuser: false,
          booted: true,
        };
      }
      // case 'SET_AUTH_KEY':
      //   {
      //     return {
      //       ...state,
      //       authkey: action.payload,
      //     };
      //   }

    case 'HANDLE_INVALIDATED_SESSION':
      {
        return {
          ...initialState,
          booted: true
        };
      }
    default:
      {
        return state;
      }
  }
};

export default reducer;
