import * as R from 'ramda';

const initialState = {
  events: [],
  pagination: {
    lastpage: false,
    page: 0,
    totalpages: 1,
  },
  query: '',
  hint: '',
  current: {
    date: '',
    end: '',
    globalid: '',
    info: '',
    name: '',
    place: '',
    remainingtickets: 0,
    start: '',
  },
  preselect: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EVENT_LIST_FULFILLED': {
      const { events, pagination, hint } = action.payload.data;
      pagination.page = parseInt(pagination.page, 10);
      return { ...state, events, pagination, hint };
    }
    case 'CLEAR_EVENT_SEARCH': {
      return { ...state, query: '' };
    }
    case 'UPDATE_EVENT_SEARCH': {
      const query = action.payload;
      return { ...state, query };
    }

    case 'SET_CURRENT_EVENT': {
      const current = action.payload !== false ? R.find(R.propEq('globalid', action.payload))(
        state.events,
      ) : initialState.current;
      return { ...state, current };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
