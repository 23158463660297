const initialState = {
  visible: false,
  pdfurl: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_CHECKOUT_POPUP':
      {
        return { ...state, visible: true };
      }
    case 'BUY_PRESELECTED_TICKET_FULFILLED':
    case 'BUY_TICKET_FULFILLED':
    case 'CANCELLATIONS_PRINT_TICKET_FULFILLED':
    case 'CANCELLATIONS_PRINT_ORDER_FULFILLED':
    case 'HISTORY_PRINT_TICKET_FULFILLED':
    case 'HISTORY_PRINT_ORDER_FULFILLED':
      {
        return { ...state, visible: false, pdfurl: action.payload.url };
      }
    case 'BUY_TICKET_REJECTED':
      {
        return { ...state, visible: false, pdfurl: false };
      }
    case 'UNSET_PDF_FILE':
      {
        return { ...state, visible: false, pdfurl: false };
      }
    case 'HIDE_CHECKOUT_POPUP':
      {
        return { ...state, visible: false };
      }
    default: {
      return state;
    }
  }
};

export default reducer;
